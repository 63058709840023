import { FC, ReactNode, useEffect, useState } from "react";
import {
  ButtonBase,
  Card,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { useMetricColors } from "./useMetricColors";
import { TooltipOrNot } from "@airmont/shared/ts/ui/tooltip";
import { useSxMerge } from "shared-ts-mui";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { If } from "@airmont/shared/ts/ui/react";

export interface MetricProps {
  label: string;
  value?: number | string;
  unit?: string;
  tooltip?: ReactNode | undefined;
  startAdornment?: ReactNode;
  layout: SizeClass;
  slotProps?: {
    Card?: {
      sx?: SxProps;
    };
  };
  onClick?: () => void;
}

export const Metric: FC<MetricProps> = (props) => {
  const theme = useTheme();
  const { backgroundColor, textColor } = useMetricColors();
  const [showValueChangeAnimation, setShowValueChangeAnimation] =
    useState(false);

  const cardSx = useSxMerge(
    { borderColor: theme.palette.divider },
    props.slotProps?.Card?.sx,
    {
      flexGrow: 1,
      minWidth: "fit-content",
      height: props.layout === SizeClass.Compact ? "35px" : "50px",
      borderStyle: "solid",
      borderWidth: "3px",
      borderRadius: "6px",
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "center",
      justifyContent: "center",
      gap: 1,
    }
  );

  useEffect(() => {
    setShowValueChangeAnimation(true);
    setTimeout(() => {
      setShowValueChangeAnimation(false);
    }, 1000);
  }, [props.value]);

  const handleClick = () => {
    props.onClick?.();
  };

  return (
    <Stack
      direction={"column"}
      useFlexGap
      gap={props.layout === SizeClass.Compact ? 0.5 : 1}
      sx={{ alignItems: "stretch" }}
    >
      <Typography
        variant={props.layout === SizeClass.Compact ? "body2" : undefined}
        align={"center"}
      >
        {props.label}
      </Typography>
      <If
        expression={props.onClick != null}
        then={(children) => (
          <ButtonBase
            onClick={handleClick}
            sx={{ flexDirection: "row", borderRadius: 2 }}
          >
            {children}
          </ButtonBase>
        )}
        else={(children) => <>{children}</>}
      >
        <TooltipOrNot title={props.tooltip}>
          <Card sx={cardSx}>
            {props.startAdornment != null && props.startAdornment}
            <Typography
              className={"value"}
              align={"center"}
              sx={{
                animation: showValueChangeAnimation
                  ? `move 1s ease`
                  : undefined,
                "@keyframes move": {
                  "0%": {
                    opacity: 0.2,
                  },
                  "30%": {
                    opacity: 0.4,
                  },
                  "70%": {
                    opacity: 0.8,
                  },
                  "100%": {
                    opacity: 1,
                  },
                },
              }}
            >
              {props.value}
            </Typography>
            {props.unit != null && <Typography>{props.unit}</Typography>}
          </Card>
        </TooltipOrNot>
      </If>
    </Stack>
  );
};
