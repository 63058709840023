import { Attributes, FC, useMemo } from "react";
import { Box, Card, Stack, Typography, useTheme } from "@mui/material";
import { Metric } from "./Metric";
import { FireplacePanel } from "./FireplacePanel";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { MathUtils, notUndef } from "@airmont/shared/ts/utils/core";
import { useCombustionPlantColors } from "./useCombustionPlantColors";
import { useTranslation } from "react-i18next";
import {
  BurnAssessmentUtils,
  FlueMetricNaturalKey,
  FlueMetricsTimeUnitEnum,
  FlueMetricValues,
} from "@airmont/firefly/shared/ts/domain";
import { BurnQualityPercentLoader } from "./BurnQualityPercentLoader";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";
import { MetricsContainer } from "./MetricsContainer";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

const defaultFlueMetricValues: FlueMetricValues = {
  burnCount: 0,
  burnHourCount: 0,
  chimneyFireCount: 0,
  sootIndex: 0,
  temperatureMax: 0,
};

export interface FluePanelProps extends Attributes {
  customerEnvironment: CustomerEnvironmentId;
  flue: Flue;
  selectedFlueMetric: FlueMetricNaturalKey;
  hideName?: boolean;
  layout: SizeClass;
  onShowMonthlySummary: () => void;
}

export const FluePanel: FC<FluePanelProps> = (props) => {
  const { t } = useTranslation("firefly-shared-ts-domain");
  const theme = useTheme();
  const hideName = props.hideName ?? false;
  const { backgroundColor } = useCombustionPlantColors();
  const { flue, selectedFlueMetric } = props;

  const flueMetrics = useMemo(() => {
    return flue.querySingleMetricsOrDefault(
      selectedFlueMetric.unit,
      selectedFlueMetric.time,
      defaultFlueMetricValues
    );
  }, [flue, selectedFlueMetric.time, selectedFlueMetric.unit]);

  const selectedFlueMetricTimeUnitIsMonth =
    selectedFlueMetric.unit === FlueMetricsTimeUnitEnum.Month;

  const handleMetricClick = () => {
    props.onShowMonthlySummary();
  };
  return (
    <Card
      className={"Flue"}
      elevation={0}
      sx={{
        backgroundColor: "inherit",
        flexGrow: 1,
        minWidth: "fit-content",
        minHeight: 0,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      {!hideName && (
        <Typography
          align={"center"}
          sx={{
            position: "absolute",
            top: 10,
            marginLeft: "50%",
            marginRight: "50%",
          }}
        >
          {flue.mcFields?.name}
        </Typography>
      )}
      <Box
        className={"FlueBody"}
        sx={{
          flexGrow: 1,
          minWidth: "fit-content",
          maxWidth: "480px",
          display: "flex",
          flexDirection: "row",
          justifyItems: "center",
        }}
      >
        <MetricsContainer
          className={"FlueLeftSide"}
          layout={props.layout}
          sx={{ pr: 1 }}
        >
          {flueMetrics != null && (
            <>
              <Metric
                label={t("Burns")}
                value={flueMetrics?.metrics.burnCount}
                layout={props.layout}
                onClick={
                  selectedFlueMetricTimeUnitIsMonth
                    ? handleMetricClick
                    : undefined
                }
              />
              <BurnQualityPercentLoader
                flue={flue}
                customerEnvironment={props.customerEnvironment}
                selectedFlueMetric={selectedFlueMetric}
              >
                {({ data: goodIgnitionsPercent }) => {
                  const goodIgnitionsColor: string | undefined = notUndef(
                    goodIgnitionsPercent,
                    BurnAssessmentUtils.percentToColor
                  );
                  const GoodIgnitionsIcon = notUndef(
                    goodIgnitionsPercent,
                    BurnAssessmentUtils.percentToIcon
                  );

                  return (
                    <Metric
                      label={t("Good Ignitions")}
                      value={goodIgnitionsPercent ?? "?"}
                      unit={goodIgnitionsPercent != null ? "%" : undefined}
                      layout={props.layout}
                      slotProps={{
                        Card: {
                          sx: { borderColor: goodIgnitionsColor },
                        },
                      }}
                      startAdornment={
                        GoodIgnitionsIcon != null && (
                          <GoodIgnitionsIcon
                            sx={{
                              color: goodIgnitionsColor,
                            }}
                          />
                        )
                      }
                      onClick={
                        selectedFlueMetricTimeUnitIsMonth
                          ? handleMetricClick
                          : undefined
                      }
                    />
                  );
                }}
              </BurnQualityPercentLoader>
            </>
          )}
        </MetricsContainer>
        <Box
          className={"FlueVisualization"}
          sx={{
            minHeight: "100px",
            minWidth: "40px",
            maxWidth: "60px",
            borderLeft: "1px solid " + theme.palette.divider,
            borderRight: "1px solid " + theme.palette.divider,
            backgroundColor: backgroundColor,
          }}
        />
        <MetricsContainer
          className={"FlueRightSide"}
          layout={props.layout}
          sx={{ pl: 1 }}
        >
          {flueMetrics != null && (
            <Metric
              label={t("Burn Hours")}
              value={MathUtils.round(flueMetrics?.metrics.burnHourCount)}
              layout={props.layout}
              onClick={
                selectedFlueMetricTimeUnitIsMonth
                  ? handleMetricClick
                  : undefined
              }
            />
          )}
        </MetricsContainer>
      </Box>
      <Stack
        className={"Fireplaces"}
        direction={"row"}
        gap={2}
        useFlexGap
        sx={{ justifyContent: "center" }}
      >
        {flue.fireplaces.map((fireplace) => {
          return (
            <FireplacePanel
              key={fireplace.id}
              fireplace={fireplace}
              layout={props.layout}
            />
          );
        })}
      </Stack>
    </Card>
  );
};
