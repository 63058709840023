import { FC, ReactNode } from "react";
import { MonthlySummaryProps } from "./MonthlySummary";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { DateTime, Duration } from "luxon";
import {
  BurnQualityCountAggregateByTimeLoader,
  BurnQualityCountDataPoints,
} from "@airmont/firefly/shared/ts/domain";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { useBurnDao } from "@airmont/firefly/my-chimney/ts/burn";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";
import { notUndef } from "@airmont/shared/ts/utils/core";

const dateTimeNowRefreshDuration = Duration.fromDurationLike({ hours: 6 });

export interface MonthlySummaryDataLoaderProps {
  customerEnvironment: CustomerEnvironmentId;
  flue: Flue;
  month: DateTime;
  children: (props: MonthlySummaryProps) => ReactNode;
}

export const MonthlySummaryDataLoader: FC<MonthlySummaryDataLoaderProps> = (
  props
) => {
  const { customerEnvironment, flue, month, children } = props;
  const now = useDateTimeNow(dateTimeNowRefreshDuration);
  const burnDao = useBurnDao({
    customerEnvironment: customerEnvironment,
  });

  return (
    <BurnQualityCountAggregateByTimeLoader
      flueId={flue.id}
      start={flue.audit.created.startOf("month")}
      end={now}
      aggregateBy={"month"}
      queryBurnQualityCountAggregate={burnDao.queryBurnQualityCountAggregate}
    >
      {(result) => {
        const dataPoints = notUndef(result.data, (data) =>
          BurnQualityCountDataPoints.fromAggregates(data)
        );

        return children({
          flue: flue,
          time: month,
          dataPoints: dataPoints ?? BurnQualityCountDataPoints.empty(),
        });
      }}
    </BurnQualityCountAggregateByTimeLoader>
  );
};
