import { CSSProperties, FC } from "react";

export interface MascotHappyProps {
  mode: "light" | "dark";
  style?: CSSProperties;
}

export const MascotHappy: FC<MascotHappyProps> = (props) => {
  const hairColor = props.mode === "dark" ? "#fff" : "#fff";
  return (
    <svg
      version="1.1"
      viewBox={"0 0 476.38 392.56"}
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
      className={"MascotHappy"}
    >
      <defs>
        <style>{`

      .MascotHappy.cls-1, .MascotHappy.cls-2 {
        fill: none;
      }

      .MascotHappy.cls-1, .MascotHappy.cls-3 {
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2.94387px;
      }

      .MascotHappy.cls-1, .MascotHappy.cls-3, .MascotHappy.cls-4 {
        stroke: #000;
      }

      .MascotHappy.cls-5 {
        fill: #94c8c8;
      }

      .MascotHappy.cls-6 {
        fill: #9cc;
      }

      .MascotHappy.cls-2 {
        stroke: #211e1f;
        stroke-width: 3.00742px;
      }

      .MascotHappy.cls-2, .MascotHappy.cls-4 {
        stroke-miterlimit: 10;
      }

      .MascotHappy.cls-7 {
        fill: #98cbcb;
      }

      .MascotHappy.cls-3, .MascotHappy.cls-8 {
        fill: #fff;
      }

      .MascotHappy.cls-9 {
        fill: #e1f1ef;
      }

      .MascotHappy.cls-10 {
        fill: #211e1f;
      }

      .MascotHappy.cls-4 {
        stroke-width: .03445px;
      }
          `}</style>
      </defs>
      <path
        className="MascotHappy cls-9"
        d="m267.91 57.225c-5.3838-39.198-55.978-57.915-86.579-33.92-16.477 15.831-17.346-8.784-32.477-13.314-12.946-2.6194-19.579 12.811-32.297 9.9844-11.123-2.4843-10.461-15.248-21.516-17.527-12.285-2.5331-16.823 9.1276-32.41 9.9372-10.738 0.55779-16.354-16.568-33.185-11.417-34.301 10.497-13.316 58.379 24.069 38.904 4.3872-2.2853 9.4439-6.7722 15.603-5.9019 10.644 1.5041 11.702 14.798 22.366 17.848 12.238 3.5005 18.368-11.854 32.298-9.9865 15.072 2.0208 16.355 20.625 31.271 22.996 12.763 2.0291 18.917-12.717 31.224-10.165 11.415 1.9411 13.523 19.176 17.664 27.556 19.882 44.364 72.182 18.891 63.968-24.995z"
      />
      <path
        className="MascotHappy cls-6"
        d="m236.08 387.6-14.147 0.64308-5.0291 0.22959-88.667 4.0876 18.05-31.645 53.439 11.436-45.309-48.915c6.1084-3.9728 12.263-7.9229 18.372-11.896 0.09195-0.04597 0.16091-0.1148 0.2523-0.16077 7.5555-4.8915 37.318-24.825 44.369-29.395 10.173 0.55113 10.701 6.5909 17.316 11.919-5.5808 4.0188-20.922 13.32-26.755 17.476-0.09195 0.04597-0.16035 0.1148-0.22875 0.16077-7.3032 5.2359-14.583 10.518-21.886 15.754l38.144 45.815 3.17 3.8121 8.91 10.679z"
      />
      <path
        className="MascotHappy cls-4"
        d="m234.73 293.91c-5.5808 4.0188-20.922 13.32-26.755 17.476h-34.929c7.5555-4.8915 37.318-24.825 44.369-29.395 10.173 0.55113 10.701 6.5909 17.316 11.919z"
      />
      <path
        className="MascotHappy cls-6"
        d="m241.03 387.6 14.147 0.64308 5.0291 0.22959 88.667 4.0876-18.05-31.645-53.439 11.436 45.309-48.915c-6.1084-3.9728-12.263-7.9229-18.372-11.896-0.09195-0.04597-0.16091-0.1148-0.2523-0.16077-7.5555-4.8915-37.318-24.825-44.369-29.395-10.173 0.55113-10.701 6.5909-17.316 11.919 5.5808 4.0188 20.922 13.32 26.755 17.476 0.09195 0.04597 0.16035 0.1148 0.22875 0.16077 7.3032 5.2359 14.583 10.518 21.886 15.754l-38.144 45.815-3.17 3.8121-8.91 10.679z"
      />
      <path
        className="MascotHappy cls-4"
        d="m242.38 293.91c5.5808 4.0188 20.922 13.32 26.755 17.476h34.929c-7.5555-4.8915-37.318-24.825-44.369-29.395-10.173 0.55113-10.701 6.5909-17.316 11.919z"
      />
      <rect
        className="MascotHappy cls-5"
        transform="translate(79.808 -30.61) rotate(19.641)"
        x="74.955"
        y="200.78"
        width="106.73"
        height="28.869"
      />
      <rect
        className="MascotHappy cls-8"
        x="9.1628"
        y="153.53"
        width="38.304"
        height="38.608"
      />
      <path
        className="MascotHappy cls-3"
        d="m11.184 143.25c-1.7994-4.0087 1.2463-9.3595 5.5878-9.8172 3.9549-0.41697 7.385 2.5316 10.319 5.2373 8.6416 7.97 18.299 15.827 29.879 17.605 2.5965 0.39857 5.3678 0.45162 7.7208-0.72443 2.6956-1.3474 4.4323-4.1133 5.467-6.9611 1.0346-2.8478 1.5007-5.8746 2.442-8.7551 1.1067-3.3869 2.8739-6.5533 5.1711-9.2653 1.7554-2.0725 3.9068-3.93 6.5049-4.6842 2.5981-0.75409 5.6982-0.18841 7.4268 1.9069 1.6008 1.94 1.738 4.7514 1.1465 7.2032-0.59153 2.4518-1.8093 4.6969-2.6923 7.058-2.5649 6.8581-2.1648 14.785 1.0773 21.345 1.9007 3.846 4.7352 7.268 5.8792 11.407 1.4629 5.2933-0.05479 10.923-1.8177 16.122-2.752 8.1162-6.339 16.327-12.826 21.878-2.243 1.9196-4.8304 3.4918-7.6805 4.2295-3.3447 0.86581-6.8808 0.54805-10.276-0.08485-6.7655-1.261-13.241-3.7318-19.675-6.1905-11.21-4.2842-22.645-8.6987-31.873-16.413-3.5259-2.9477-6.803-6.5651-7.8766-11.054-0.61283-2.5618-0.20634-5.7426 2.0502-7.0736 1.8599-1.097 4.3083-0.48991 6.0649 0.76832 1.7567 1.2582 3.0355 3.0641 4.5109 4.6474 3.2178 3.4529 7.4873 5.8963 12.078 6.9122"
      />
      <path
        className="MascotHappy cls-3"
        d="m4.9542 159.24c-0.97407-2.048-1.8245-4.186-2.1369-6.4353-0.31232-2.2494-0.05043-4.6354 1.0834-6.5981 0.56773-0.98258 1.3623-1.8554 2.3667-2.3729 0.70907-0.36544 1.5022-0.54449 2.2964-0.60383 3.4111-0.25486 6.6522 1.6544 9.1013 4.0612 2.449 2.4067 4.3341 5.3336 6.6666 7.8552 3.676 3.9737 8.4167 6.8666 13.482 8.7234"
      />
      <path
        className="MascotHappy cls-3"
        d="m6.8108 176.35c-3.6854-2.4905-5.7995-7.1561-5.2537-11.594 0.25704-2.0899 1.1877-4.2526 3.0193-5.2659 2.0687-1.1445 4.7274-0.49577 6.6615 0.86714 1.934 1.3629 3.3464 3.3305 4.906 5.1158 4.8727 5.5778 11.499 9.5758 18.678 11.27"
      />
      <path
        className="MascotHappy cls-8"
        d="m42.57 165.6c-4.414 8.5948-5.3415 18.945-2.5268 28.198"
      />
      <path d="m42.57 165.6c-2.4668 9.327-3.2771 18.581-2.5266 28.198-4.8907-8.6421-3.8114-20.559 2.5266-28.198z" />
      <path
        className="MascotHappy cls-1"
        d="m11.184 143.25c-1.7994-4.0087 1.2463-9.3595 5.5878-9.8172 3.9549-0.41697 7.385 2.5316 10.319 5.2373 8.6416 7.97 18.299 15.827 29.879 17.605 2.5965 0.39857 5.3678 0.45162 7.7208-0.72443 2.6956-1.3474 4.4323-4.1133 5.467-6.9611 1.0346-2.8478 1.5007-5.8746 2.442-8.7551 1.1067-3.3869 2.8739-6.5533 5.1711-9.2653 1.7554-2.0725 3.9068-3.93 6.5049-4.6842 2.5981-0.75409 5.6982-0.18841 7.4268 1.9069 1.6008 1.94 1.738 4.7514 1.1465 7.2032-0.59153 2.4518-1.8093 4.6969-2.6923 7.058-2.5649 6.8581-2.1648 14.785 1.0773 21.345 1.9007 3.846 4.7352 7.268 5.8792 11.407 1.4629 5.2933-0.05479 10.923-1.8177 16.122-2.752 8.1162-6.339 16.327-12.826 21.878-2.243 1.9196-4.8304 3.4918-7.6805 4.2295-3.3447 0.86581-6.8808 0.54805-10.276-0.08485-6.7655-1.261-13.241-3.7318-19.675-6.1905-11.21-4.2842-22.645-8.6987-31.873-16.413-3.5259-2.9477-6.803-6.5651-7.8766-11.054-0.61283-2.5618-0.20634-5.7426 2.0502-7.0736 1.8599-1.097 4.3083-0.48991 6.0649 0.76832 1.7567 1.2582 3.0355 3.0641 4.5109 4.6474 3.2178 3.4529 7.4873 5.8963 12.078 6.9122"
      />
      <rect
        className="MascotHappy cls-5"
        transform="translate(748.21 300.92) rotate(160.36)"
        x="294.7"
        y="200.78"
        width="106.73"
        height="28.869"
      />
      <rect
        className="MascotHappy cls-8"
        transform="translate(896.13 345.68) rotate(180)"
        x="428.91"
        y="153.53"
        width="38.304"
        height="38.608"
      />
      <path
        className="MascotHappy cls-3"
        d="m465.2 143.25c1.7994-4.0087-1.2463-9.3595-5.5878-9.8172-3.9549-0.41697-7.385 2.5316-10.319 5.2373-8.6416 7.97-18.299 15.827-29.879 17.605-2.5965 0.39857-5.3678 0.45162-7.7208-0.72443-2.6956-1.3474-4.4323-4.1133-5.467-6.9611-1.0346-2.8478-1.5007-5.8746-2.442-8.7551-1.1067-3.3869-2.8739-6.5533-5.1711-9.2653-1.7554-2.0725-3.9068-3.93-6.5049-4.6842-2.5981-0.75409-5.6982-0.18841-7.4268 1.9069-1.6008 1.94-1.738 4.7514-1.1465 7.2032 0.59153 2.4518 1.8093 4.6969 2.6923 7.058 2.5649 6.8581 2.1648 14.785-1.0773 21.345-1.9007 3.846-4.7352 7.268-5.8792 11.407-1.4629 5.2933 0.05479 10.923 1.8177 16.122 2.752 8.1162 6.339 16.327 12.826 21.878 2.243 1.9196 4.8304 3.4918 7.6805 4.2295 3.3447 0.86581 6.8808 0.54805 10.276-0.08485 6.7655-1.261 13.241-3.7318 19.675-6.1905 11.21-4.2842 22.645-8.6987 31.873-16.413 3.5259-2.9477 6.803-6.5651 7.8766-11.054 0.61283-2.5618 0.20634-5.7426-2.0502-7.0736-1.8599-1.097-4.3083-0.48991-6.0649 0.76832-1.7567 1.2582-3.0355 3.0641-4.5109 4.6474-3.2178 3.4529-7.4873 5.8963-12.078 6.9122"
      />
      <path
        className="MascotHappy cls-3"
        d="m471.43 159.24c0.97407-2.048 1.8245-4.186 2.1369-6.4353 0.31232-2.2494 0.05043-4.6354-1.0834-6.5981-0.56773-0.98258-1.3623-1.8554-2.3667-2.3729-0.70907-0.36544-1.5022-0.54449-2.2964-0.60383-3.4111-0.25486-6.6522 1.6544-9.1013 4.0612-2.449 2.4067-4.3341 5.3336-6.6666 7.8552-3.676 3.9737-8.4167 6.8666-13.482 8.7234"
      />
      <path
        className="MascotHappy cls-3"
        d="m469.57 176.35c3.6854-2.4905 5.7995-7.1561 5.2537-11.594-0.25704-2.0899-1.1877-4.2526-3.0193-5.2659-2.0687-1.1445-4.7274-0.49577-6.6615 0.86714-1.934 1.3629-3.3464 3.3305-4.906 5.1158-4.8727 5.5778-11.499 9.5758-18.678 11.27"
      />
      <path
        className="MascotHappy cls-8"
        d="m433.81 165.6c4.414 8.5948 5.3415 18.945 2.5268 28.198"
      />
      <path d="m433.81 165.6c6.3378 7.6386 7.4174 19.556 2.527 28.198 0.75006-9.6166-0.06031-18.871-2.527-28.198z" />
      <path
        className="MascotHappy cls-1"
        d="m465.2 143.25c1.7994-4.0087-1.2463-9.3595-5.5878-9.8172-3.9549-0.41697-7.385 2.5316-10.319 5.2373-8.6416 7.97-18.299 15.827-29.879 17.605-2.5965 0.39857-5.3678 0.45162-7.7208-0.72443-2.6956-1.3474-4.4323-4.1133-5.467-6.9611-1.0346-2.8478-1.5007-5.8746-2.442-8.7551-1.1067-3.3869-2.8739-6.5533-5.1711-9.2653-1.7554-2.0725-3.9068-3.93-6.5049-4.6842-2.5981-0.75409-5.6982-0.18841-7.4268 1.9069-1.6008 1.94-1.738 4.7514-1.1465 7.2032 0.59153 2.4518 1.8093 4.6969 2.6923 7.058 2.5649 6.8581 2.1648 14.785-1.0773 21.345-1.9007 3.846-4.7352 7.268-5.8792 11.407-1.4629 5.2933 0.05479 10.923 1.8177 16.122 2.752 8.1162 6.339 16.327 12.826 21.878 2.243 1.9196 4.8304 3.4918 7.6805 4.2295 3.3447 0.86581 6.8808 0.54805 10.276-0.08485 6.7655-1.261 13.241-3.7318 19.675-6.1905 11.21-4.2842 22.645-8.6987 31.873-16.413 3.5259-2.9477 6.803-6.5651 7.8766-11.054 0.61283-2.5618 0.20634-5.7426-2.0502-7.0736-1.8599-1.097-4.3083-0.48991-6.0649 0.76832-1.7567 1.2582-3.0355 3.0641-4.5109 4.6474-3.2178 3.4529-7.4873 5.8963-12.078 6.9122"
      />
      <path
        className="MascotHappy cls-7"
        d="m144.41 115.12h188.42v165.57c0 12.611-10.239 22.85-22.85 22.85h-142.72c-12.611 0-22.85-10.239-22.85-22.85v-165.57z"
      />
      <rect
        className="MascotHappy cls-7"
        x="110.76"
        y="94.777"
        width="255.73"
        height="49.298"
      />
      <rect
        x="198.04"
        y="152.65"
        width="81.167"
        height="7.4786"
        rx="3.7393"
        ry="3.7393"
      />
      <path
        className="MascotHappy cls-2"
        d="m216.12 224.75c3.7183 3.0811 12.611 9.4655 24.546 8.6793 9.6247-0.63395 16.796-5.6393 20.45-8.6794"
      />
      <path
        className="MascotHappy cls-8"
        d="m280.89 187.19c0 3.7485-1.0604 7.2552-2.8927 10.232h-33.28c-1.8324-2.9764-2.8927-6.4831-2.8927-10.232 0-10.79 8.7434-19.533 19.533-19.533 10.79 0 19.533 8.7432 19.533 19.533z"
      />
      <circle
        className="MascotHappy cls-10"
        cx="260.27"
        cy="187.66"
        r="4.6507"
      />
      <path
        className="MascotHappy cls-8"
        d="m234.48 187.19c0 3.7485-1.0604 7.2552-2.8927 10.232h-33.28c-1.8324-2.9764-2.8927-6.4831-2.8927-10.232 0-10.79 8.7434-19.533 19.533-19.533 10.79 0 19.533 8.7432 19.533 19.533z"
      />
      <circle
        className="MascotHappy cls-10"
        cx="213.86"
        cy="187.66"
        r="4.6507"
      />
    </svg>
  );
};
