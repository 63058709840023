import { FC, ReactNode, useMemo } from "react";
import {
  Burn,
  BurnDto,
  BurnTemperatureSeries,
  BurnTemperatureSeriesImpl,
  FlueId,
} from "@airmont/firefly/shared/ts/domain";
import { FetchStatus, QueryStatus, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import {
  _throw,
  IllegalStateError,
  notUndef,
} from "@airmont/shared/ts/utils/core";
import { useBurnDao } from "./useBurnDao";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";

export interface BurnsLoadingResult {
  burns: Array<Burn> | undefined;
  burnsStatus: QueryStatus;
  burnsFetchStatus: FetchStatus;
  isAnonymizedTime: boolean | undefined;
  burnsAreLoading: boolean;
  burnsAreFetching: boolean;
  burnTemperatureSeries: Array<BurnTemperatureSeries> | undefined;
  burnTemperatureSeriesStatus: QueryStatus;
  burnTemperatureSeriesFetchStatus: FetchStatus;
  burnTemperatureSeriesAreLoading: boolean;
  burnTemperatureSeriesAreFetching: boolean;
}

export interface BurnsLoaderProps {
  environment: CustomerEnvironmentId;
  flueId: FlueId;
  fromDate: DateTime;
  toDate: DateTime;
  children: (result: BurnsLoadingResult) => ReactNode;
}

export const BurnsLoader: FC<BurnsLoaderProps> = (props) => {
  const burnDao = useBurnDao({ customerEnvironment: props.environment });
  const {
    data: burnDtos,
    isLoading: isBurnsLoading,
    isFetching: isBurnsFetching,
    fetchStatus: burnsFetchStatus,
    status: burnsStatus,
  } = useQuery({
    queryKey: [
      "BurnDao.getByFlue",
      props.flueId,
      props.fromDate,
      props.toDate,
      props.environment,
    ],
    queryFn: async () => {
      return await burnDao.getByFlue(
        props.environment,
        props.flueId,
        props.fromDate,
        props.toDate
      );
    },
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });
  const { burns, isAnonymizedTime } = useMemo(() => {
    return burnDtosToBurns(burnDtos);
  }, [burnDtos]);
  const burnIds = useMemo(() => {
    return burns?.map((it) => it.id);
  }, [burns]);

  const {
    data: temperatureSeriesDtos,
    isLoading: isTemperatureSeriesLoading,
    isFetching: isTemperatureSeriesFetching,
    fetchStatus: burnTemperatureSeriesFetchStatus,
    status: burnTemperatureSeriesStatus,
  } = useQuery({
    queryKey: [
      "BurnDao.getTemperatureSeriesByBurn",
      burnIds,
      props.environment,
    ],
    queryFn: () =>
      notUndef(burnIds, (burnIds) =>
        burnDao.getTemperatureSeriesByBurn(props.environment, burnIds)
      ) ?? _throw(new IllegalStateError("burnIds undefined")),
    enabled: burnIds !== undefined,
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });
  const burnTemperatureSeries = useMemo(() => {
    return temperatureSeriesDtos?.map((dto) =>
      BurnTemperatureSeriesImpl.fromDto(dto)
    );
  }, [temperatureSeriesDtos]);

  const memoizedResult = useMemo(
    () => ({
      isAnonymizedTime: isAnonymizedTime,
      burns: burns,
      burnsStatus: burnsStatus,
      burnsFetchStatus: burnsFetchStatus,
      burnsAreLoading: isBurnsLoading,
      burnsAreFetching: isBurnsFetching,
      burnTemperatureSeries: burnTemperatureSeries,
      burnTemperatureSeriesStatus: burnTemperatureSeriesStatus,
      burnTemperatureSeriesFetchStatus: burnTemperatureSeriesFetchStatus,
      burnTemperatureSeriesAreLoading: isTemperatureSeriesLoading,
      burnTemperatureSeriesAreFetching: isTemperatureSeriesFetching,
    }),
    [
      burnTemperatureSeries,
      burnTemperatureSeriesFetchStatus,
      burnTemperatureSeriesStatus,
      burnsFetchStatus,
      burns,
      isAnonymizedTime,
      burnsStatus,
      isBurnsFetching,
      isBurnsLoading,
      isTemperatureSeriesFetching,
      isTemperatureSeriesLoading,
    ]
  );
  return props.children(memoizedResult);
};

const burnDtosToBurns = (
  burnDtos: Array<BurnDto> | undefined
): {
  burns: Array<Burn> | undefined;
  isAnonymizedTime: boolean | undefined;
} => {
  const countByDate: Record<string, number> = {};

  if (burnDtos === undefined) {
    return {
      burns: undefined,
      isAnonymizedTime: undefined,
    };
  }
  const reversed = burnDtos.slice().reverse();
  let isAnonymizedTime = false;
  const withDayCount = reversed
    .map((it, index) => {
      if (!isAnonymizedTime && it.anonymized) {
        isAnonymizedTime = true;
      }
      const startTimeAsDateTime = DateTime.fromISO(it.startTime);
      const startTimeDate = startTimeAsDateTime.toFormat("yyyy-MM-dd");
      let dayCount = countByDate[startTimeDate];
      if (dayCount == null) {
        dayCount = 1;
        countByDate[startTimeDate] = dayCount;
      } else {
        dayCount++;
        countByDate[startTimeDate] = dayCount;
      }
      return new Burn(it, { count: index + 1, countOfDay: dayCount });
    })
    .reverse();
  const result: Array<Burn> = [];
  withDayCount.forEach((burn, index) => {
    const prevBurn = index > 0 ? withDayCount[index - 1] : undefined;
    if (
      burn.startTime.day !== prevBurn?.startTime.day &&
      burn.countOfDay === 1
    ) {
      result.push(
        new Burn(
          { ...burn.toDto() },
          { count: burn.count, countOfDay: undefined }
        )
      );
    } else {
      result.push(burn);
    }
  });
  return { burns: result, isAnonymizedTime: isAnonymizedTime };
};
