import { FC, useMemo } from "react";
import { Button, Stack, Toolbar, Typography, useTheme } from "@mui/material";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { DateTime, Info, Interval } from "luxon";
import {
  BurnAssessment,
  BurnQualityCountDataPoints,
  BurnQualityCountDataPointsChart,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { BurnStartAssessmentInfo } from "./BurnStartAssessmentInfo";
import { SizeClass, useResponsive } from "@airmont/shared/ts/ui/responsive";
import { ModalPanel } from "../../shared/ModalPanel";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import TrendingDownRoundedIcon from "@mui/icons-material/TrendingDownRounded";
import TrendingFlatRoundedIcon from "@mui/icons-material/TrendingFlatRounded";
import { darkModeTextShadow } from "../../shared/darkMode_textShadow";
import { useHotkeys } from "react-hotkeys-hook";
import { Key } from "ts-key-enum";
import { useTranslation } from "react-i18next";
import { useMascotFeedback } from "./useMascotFeedback";

export interface MonthlySummaryProps {
  flue: Flue;
  time: DateTime;
  dataPoints: BurnQualityCountDataPoints;
  onClose?: () => void;
}

export const MonthlySummary: FC<MonthlySummaryProps> = (props) => {
  const { flue, time, dataPoints } = props;
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const { t: tSharedDomain } = useTranslation("firefly-shared-ts-domain");
  const theme = useTheme();
  const responsive = useResponsive();
  const metrics = flue.querySingleMetricsOrUndef(
    FlueMetricsTimeUnitEnum.Month,
    time
  );
  const indexOfCurrentMonthDataPoint = dataPoints.findIndexOfTime(time);
  const currentMonthDataPoint = dataPoints[indexOfCurrentMonthDataPoint];
  const previousMonthDataPoint =
    indexOfCurrentMonthDataPoint > 0
      ? dataPoints[indexOfCurrentMonthDataPoint - 1]
      : undefined;

  const changeSincePrevious =
    currentMonthDataPoint.goodInPercent != null &&
    previousMonthDataPoint?.goodInPercent != null
      ? currentMonthDataPoint.goodInPercent -
        previousMonthDataPoint.goodInPercent
      : undefined;

  const months = Info.months();
  useHotkeys(Key.Escape, () => {
    props.onClose?.();
  });
  const handleCloseClick = () => {
    props.onClose?.();
  };

  const interval = useMemo(() => {
    return Interval.before(time.plus({ month: 1 }), {
      months: 6,
    }) as Interval<true>;
  }, [time]);

  const feedBackMascot = useMascotFeedback(currentMonthDataPoint, theme);

  return (
    <ModalPanel className={"MonthlySummary"} hideMascot>
      <Stack
        sx={{
          pt: 1,
          flexGrow: 1,
          minHeight: "fit-content",
          justifyContent: "space-evenly",
        }}
      >
        <Stack
          useFlexGap
          gap={1}
          sx={{
            flexGrow: 1,
            height: 0,
            minHeight: 0,
            justifyContent: "space-evenly",
            overflowY: "auto",
          }}
        >
          {feedBackMascot}
          <Stack
            useFlexGap
            gap={2}
            sx={{
              flexGrow: 1,
              minHeight: "fit-content",
              justifyContent: "end",
            }}
          >
            <Stack sx={{ borderBottom: "2px solid " + theme.palette.divider }}>
              <Typography
                variant={"h6"}
                sx={{
                  fontWeight: "normal",
                  textShadow:
                    theme.palette.mode === "dark"
                      ? darkModeTextShadow
                      : undefined,
                }}
              >
                {t("Summary for")}
              </Typography>
              <Typography
                variant={"h6"}
                sx={{
                  textShadow:
                    theme.palette.mode === "dark"
                      ? darkModeTextShadow
                      : undefined,
                }}
              >
                {currentMonthDataPoint?.time != null
                  ? `${months[time.month - 1].capitalizeFirstLetter()} ${
                      time.year
                    }`
                  : "?"}
              </Typography>
            </Stack>
            <Stack useFlexGap gap={2}>
              <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    textShadow:
                      theme.palette.mode === "dark"
                        ? darkModeTextShadow
                        : undefined,
                  }}
                >
                  {tSharedDomain("Number of Burns")}
                </Typography>
                <Typography
                  sx={{
                    textShadow:
                      theme.palette.mode === "dark"
                        ? darkModeTextShadow
                        : undefined,
                  }}
                >
                  {metrics?.metrics.burnCount}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                useFlexGap
                gap={1}
                sx={{ justifyContent: "space-around" }}
              >
                <BurnStartAssessmentInfo
                  assessment={BurnAssessment.Good}
                  value={currentMonthDataPoint?.excellent}
                />
                <BurnStartAssessmentInfo
                  assessment={BurnAssessment.Normal}
                  value={currentMonthDataPoint?.good}
                />
                <BurnStartAssessmentInfo
                  assessment={BurnAssessment.Bad}
                  value={currentMonthDataPoint?.bad}
                />
              </Stack>
            </Stack>
            <Stack
              direction={"column"}
              sx={{ justifyContent: "space-between" }}
            >
              <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
                <Typography>{tSharedDomain("Good Ignitions")}</Typography>
                <Typography>
                  {notUndef(currentMonthDataPoint, (it) =>
                    notUndef(it.goodInPercent, (it) => `${it} %`)
                  )}
                </Typography>
              </Stack>
              {changeSincePrevious != null && (
                <Stack
                  direction={"row"}
                  useFlexGap
                  gap={4.5}
                  sx={{ pl: 2, justifyContent: "end" }}
                >
                  {changeSincePrevious > 0 ? (
                    <TrendingUpRoundedIcon />
                  ) : changeSincePrevious < 0 ? (
                    <TrendingDownRoundedIcon />
                  ) : (
                    <TrendingFlatRoundedIcon />
                  )}
                  <Typography
                    color={"text.secondary"}
                  >{`${changeSincePrevious} %`}</Typography>
                </Stack>
              )}
              <BurnQualityCountDataPointsChart
                dataPoints={dataPoints}
                interval={interval}
                timeUnit={"month"}
                layout={SizeClass.Compact}
                hideXAxis
                hideYAxisLabels
                sx={{
                  height: "100px",
                  "&&& text": {
                    fill: theme.palette.text.secondary,
                  },
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Toolbar
          {...responsive.toolbar}
          disableGutters
          sx={{ justifyContent: "center" }}
        >
          <Button {...responsive.component} onClick={handleCloseClick}>
            {t("Close")}
          </Button>
        </Toolbar>
      </Stack>
    </ModalPanel>
  );
};
