import { CSSProperties, FC } from "react";

const moveRight = 150;

export interface MascotSleepingProps {
  mode: "light" | "dark";
  style?: CSSProperties;
}

export const MascotSleeping: FC<MascotSleepingProps> = (props) => {
  const hairColor = props.mode === "dark" ? "#fff" : "#fff";
  return (
    <svg
      version="1.1"
      viewBox={`-${moveRight} 0 ${319.21 + moveRight * 2.5} 385.15`}
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
      className={"MascotSleeping"}
    >
      <defs>
        <style>{`

          .MascotSleeping.cls-1, .MascotSleeping.cls-2 {
            fill: none;
          }

          .MascotSleeping.cls-1, .MascotSleeping.cls-3 {
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2.94387px;
          }

          .MascotSleeping.cls-1, .MascotSleeping.cls-3, .MascotSleeping.cls-4 {
            stroke: #000;
          }

          .MascotSleeping.cls-5 {
            fill: #94c8c8;
          }

          .MascotSleeping.cls-6 {
            fill: #9cc;
          }

          .MascotSleeping.cls-2 {
            stroke: #211e1f;
            stroke-width: 3.00742px;
          }

          .MascotSleeping.cls-2, .MascotSleeping.cls-4 {
            stroke - miterlimit: 10;
          }

          .MascotSleeping.cls-7 {
            fill: #98cbcb;
          }

          .MascotSleeping.cls-3, .MascotSleeping.cls-8 {
            fill: #fff;
          }

          .MascotSleeping.cls-9 {
            fill: #e1f1ef;
          }

          .MascotSleeping.cls-10 {
            fill: #211e1f;
          }

          .MascotSleeping.cls-4 {
            stroke - width: .03445px;
          }
        `}</style>
      </defs>
      <g transform="translate(-47.268 -7.4105)">
        <path
          className="MascotSleeping cls-6"
          d="m236.08 387.6-14.147 0.64308-5.0291 0.22959-88.667 4.0876 18.05-31.645 53.439 11.436-45.309-48.915c6.1084-3.9728 12.263-7.9229 18.372-11.896 0.0919-0.046 0.16091-0.1148 0.2523-0.16077 7.5555-4.8915 37.318-24.825 44.369-29.395 10.173 0.55113 10.701 6.5909 17.316 11.919-5.5808 4.0188-20.922 13.32-26.755 17.476-0.0919 0.046-0.16035 0.1148-0.22875 0.16077-7.3032 5.2359-14.583 10.518-21.886 15.754l38.144 45.815 3.17 3.8121 8.91 10.679z"
        />
        <path
          className="MascotSleeping cls-4"
          d="m234.73 293.91c-5.5808 4.0188-20.922 13.32-26.755 17.476h-34.929c7.5555-4.8915 37.318-24.825 44.369-29.395 10.173 0.55113 10.701 6.5909 17.316 11.919z"
        />
        <path
          className="MascotSleeping cls-6"
          d="m241.03 387.6 14.147 0.64308 5.0291 0.22959 88.667 4.0876-18.05-31.645-53.439 11.436 45.309-48.915c-6.1084-3.9728-12.263-7.9229-18.372-11.896-0.0919-0.046-0.16091-0.1148-0.2523-0.16077-7.5555-4.8915-37.318-24.825-44.369-29.395-10.173 0.55113-10.701 6.5909-17.316 11.919 5.5808 4.0188 20.922 13.32 26.755 17.476 0.0919 0.046 0.16035 0.1148 0.22875 0.16077 7.3032 5.2359 14.583 10.518 21.886 15.754l-38.144 45.815-3.17 3.8121-8.91 10.679z"
        />
        <path
          className="MascotSleeping cls-4"
          d="m242.38 293.91c5.5808 4.0188 20.922 13.32 26.755 17.476h34.929c-7.5555-4.8915-37.318-24.825-44.369-29.395-10.173 0.55113-10.701 6.5909-17.316 11.919z"
        />
        <path
          className="MascotSleeping cls-7"
          d="m144.41 115.12h188.42v165.57c0 12.611-10.239 22.85-22.85 22.85h-142.72c-12.611 0-22.85-10.239-22.85-22.85v-165.57z"
        />
        <rect
          className="MascotSleeping cls-7"
          x="110.76"
          y="94.777"
          width="255.73"
          height="49.298"
        />
      </g>
      <rect
        x="150.77"
        y="145.24"
        width="81.167"
        height="7.4786"
        rx="3.7393"
        ry="3.7393"
      />
      <path
        className="MascotSleeping cls-8"
        d="m187.25 178.05c0 0.56471-1.0604 1.093-2.8927 1.5414h-33.28c-1.8324-0.44839-2.8927-0.97667-2.8927-1.5414 0-1.6255 8.7434-2.9426 19.533-2.9426 10.79 0 19.533 1.3172 19.533 2.9426z"
        strokeWidth=".38814"
      />
      <path
        className="MascotSleeping cls-8"
        d="m234.95 178.08c0 0.56471-1.0604 1.093-2.8927 1.5414h-33.28c-1.8324-0.44839-2.8927-0.97667-2.8927-1.5414 0-1.6255 8.7434-2.9426 19.533-2.9426 10.79 0 19.533 1.3172 19.533 2.9426z"
        fill="#fff"
        strokeWidth=".38814"
      />
      <circle
        className="MascotSleeping cls-10"
        cx="191.87"
        cy="225.67"
        r="4.6507"
      />
      <g fill="#e1f1ef" fontFamily="'Roboto' 'Helvetica', 'Arial', sans-serif">
        <text
          fontWeight={700}
          transform="scale(1.0328 .96827)"
          x="169.53706"
          y="83.763374"
          fontSize="39.065px"
          strokeWidth=".71028"
        >
          <tspan x="169.53706" y="83.763374" strokeWidth=".71028">
            Z
          </tspan>
        </text>
        <text
          fontWeight={700}
          transform="scale(1.0642 .93963)"
          x="118.38192"
          y="55.58913"
          fontSize="51.68px"
          strokeWidth=".93963"
        >
          <tspan x="118.38192" y="55.58913" strokeWidth=".93963">
            Z
          </tspan>
        </text>
        <text
          fontWeight={700}
          transform="scale(1.0466 .95545)"
          x="60.4422"
          y="76.961891"
          fontSize="66.552px"
          strokeWidth="1.21"
        >
          <tspan x="60.4422" y="76.961891" strokeWidth="1.21">
            Z
          </tspan>
        </text>
        <text
          fontWeight={700}
          transform="scale(1.0322 .96883)"
          x="-1.7516199"
          y="51.427559"
          fontSize="70.065px"
          strokeWidth="1.2739"
        >
          <tspan x="-1.7516199" y="51.427559" strokeWidth="1.2739">
            Z
          </tspan>
        </text>
      </g>
    </svg>
  );
};
