import { useMemo } from "react";
import { DateTime } from "luxon";
import { TimeframeUnit } from "@airmont/shared/ts/ui/timeframe";
import { FlueMetricNaturalKey } from "@airmont/firefly/shared/ts/domain";

export const useConvertFlueMetricNaturalKeyToTimeframeProps = (args: {
  flueMetricTimeItem: FlueMetricNaturalKey;
  now: DateTime<true>;
}): { timeframeEnd: DateTime<true> } | undefined => {
  const { flueMetricTimeItem, now } = args;
  return useMemo(() => {
    if (flueMetricTimeItem.unit === "Ever") {
      return {
        timeframeEnd: now,
      };
    } else if (flueMetricTimeItem.unit === "SinceSweep") {
      return {
        timeframeEnd: now,
      };
    }

    if (
      flueMetricTimeItem.time == null ||
      flueMetricTimeItem.unit === "AtSweep"
    ) {
      return undefined;
    }
    const timeframeUnit =
      flueMetricTimeItem.unit.toLowerCase() as unknown as TimeframeUnit;
    const timeframeStart = flueMetricTimeItem.time;
    const timeframeEnd = timeframeStart.plus({ [timeframeUnit]: 1 });

    return {
      timeframeEnd: timeframeEnd,
    };
  }, [flueMetricTimeItem.unit, flueMetricTimeItem.time, now]);
};
