import { StepProps } from "./StepProps";
import React, { FC } from "react";
import { Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { BurnAssessmentLegend } from "@airmont/firefly/my-chimney/ts/burn";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { darkModeTextShadow } from "../shared/darkMode_textShadow";

export const Step2: FC<StepProps> = (props) => {
  const { paletteMode } = props;
  return (
    <>
      <Typography
        variant="h2"
        align="center"
        sx={{
          fontWeight: 400,
          maxWidth: "400px",
          textShadow: paletteMode === "dark" ? darkModeTextShadow : undefined,
        }}
      >
        <Trans
          i18nKey={"Introduction.welcome"}
          ns={"firefly-my-chimney-ts-pages"}
          components={{ b: <strong /> }}
        />
      </Typography>
      <BurnAssessmentLegend
        layout={SizeClass.Medium}
        direction={"column"}
        hideLabel
        sx={{ gap: 1, fontWeight: 700 }}
      />
      <Typography
        variant="h5"
        align="center"
        sx={{
          mt: 2,
          mb: 2,
          maxWidth: "250px",
          borderRadius: 3,
          textShadow: paletteMode === "dark" ? darkModeTextShadow : undefined,
        }}
      >
        <Trans
          i18nKey={"Introduction.step.2"}
          ns={"firefly-my-chimney-ts-pages"}
          components={{ b: <strong /> }}
        />
      </Typography>
    </>
  );
};
