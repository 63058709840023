import React, { FC, ReactNode } from "react";
import { Box, Container, Stack, useTheme } from "@mui/material";
import {
  SizeClass,
  useWindowHeightComparer,
  useWindowWidth,
} from "@airmont/shared/ts/ui/responsive";
import { MascotPeepingFromLeft } from "@airmont/firefly/my-chimney/ts/shared";
import { useWindowSize } from "usehooks-ts";

export interface ModalPanelProps {
  className?: string;
  children?: ReactNode;
  hideMascot?: boolean;
}

export const ModalPanel: FC<ModalPanelProps> = (props) => {
  const theme = useTheme();
  const hideMascot = props.hideMascot ?? false;
  const width = useWindowWidth();
  const { height: heightInPx } = useWindowSize();
  const heightComparer = useWindowHeightComparer();
  const mascotOpacity =
    width === SizeClass.Compact || heightComparer.lte(SizeClass.Medium) ? 1 : 1;

  return (
    <Box
      className={
        props.className != null ? `ModalPanel ${props.className}` : "ModalPanel"
      }
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        maxWidth={"xs"}
        sx={{
          flexGrow: 1,
          minHeight: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          ...(width === SizeClass.Compact && {
            p: 0,
          }),
        }}
      >
        <Box
          sx={{
            mt: 2,
            mb: 2,
            maxHeight: "800px",
            width: "100%",
            flexGrow: 1,
            minHeight: 0,
            borderStyle: "solid",
            borderColor: theme.palette.divider,
            borderRadius: "10px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            ...(width === SizeClass.Compact && {
              mt: 0,
              mb: 0,
              borderStyle: "none",
            }),
          }}
        >
          <Stack
            sx={{
              position: "absolute",
              zIndex: 1,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              alignItems: "center",
            }}
          >
            {props.children}
          </Stack>
          {!hideMascot && (
            <MascotPeepingFromLeft
              mode={theme.palette.mode}
              style={{
                flexGrow: 1,
                minHeight: 0,
                maxHeight: heightInPx < 700 ? "450px" : "520px",
                marginTop: "10px",
                marginBottom: "10px",
                opacity: mascotOpacity,
              }}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};
