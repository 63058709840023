import React, { FC } from "react";
import { Stack, SxProps } from "@mui/material";
import {
  Burn,
  BurnAssessmentInfo,
  WeatherInfo,
} from "@airmont/firefly/shared/ts/domain";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { Button_color, useSxMerge } from "shared-ts-mui";
import { BurnInfoPill } from "./BurnInfoPill";

export interface BurnInfoProps {
  burn: Burn;
  selected?: boolean;
  hideQuality?: boolean;
  hideDate?: boolean;
  hideEndDate?: boolean;
  color?: Button_color;
  variant?: "normal" | "outlined" | "filled";
  layout: SizeClass;
  sx?: SxProps;
  onSelect?: (burn: Burn) => void;
}

export const BurnInfo: FC<BurnInfoProps> = (props) => {
  const { burn, layout } = props;
  const sx = useSxMerge(props.sx, {
    position: "relative",
    overflowX: "auto",
    minHeight: "fit-content",
    alignItems: "center",
    scrollbarWidth: "thin",
  });

  return (
    <Stack className={"BurnInfo"} useFlexGap gap={1} direction={"row"} sx={sx}>
      <BurnInfoPill
        color={props.color}
        variant={props.variant}
        burn={burn}
        layout={layout}
      />
      {!(props.hideQuality ?? true) && (
        <BurnAssessmentInfo
          color={props.color}
          variant={props.variant}
          value={burn.analytics.startQuality}
          layout={layout}
          sx={{ marginLeft: 0 }}
        />
      )}
      <WeatherInfo
        color={props.color}
        variant={props.variant}
        weather={burn.weather}
        layout={layout}
        sx={{ paddingLeft: 0 }}
      />
    </Stack>
  );
};
