import {
  BurnQualityCountAggregateByTimeChart,
  BurnQualityCountAggregateByTimeLoader,
} from "@airmont/firefly/shared/ts/domain";
import React, { FC, useMemo } from "react";
import {
  TimeframeObject,
  TimeframeUnit,
  TimeframeUtils,
} from "@airmont/shared/ts/ui/timeframe";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { useBurnDao } from "@airmont/firefly/my-chimney/ts/burn";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { Duration } from "luxon";

const dateTimeNowRefreshDuration = Duration.fromDurationLike({ hours: 6 });

export interface BurnQualityContentProps {
  customerEnvironment: CustomerEnvironmentId;
  flue: Flue;
  timeframeProps: {
    timeframe: TimeframeObject;
    timeframeUnit: TimeframeUnit;
  };
  showGoodInPercent: boolean;
  showBurnQualityCount: boolean;
  layout: SizeClass;
}

export const BurnQualityContent: FC<BurnQualityContentProps> = (props) => {
  const { flue, timeframeProps, layout } = props;

  const now = useDateTimeNow(dateTimeNowRefreshDuration);
  const burnDao = useBurnDao({
    customerEnvironment: props.customerEnvironment,
  });
  const expandedTimeUnit = TimeframeUtils.expandUnit(
    timeframeProps.timeframeUnit
  );
  const start = useMemo(
    () => flue.audit.created.startOf(expandedTimeUnit),
    [expandedTimeUnit, flue.audit.created]
  );

  return (
    <BurnQualityCountAggregateByTimeLoader
      flueId={flue.id}
      start={start}
      end={now}
      aggregateBy={expandedTimeUnit}
      queryBurnQualityCountAggregate={burnDao.queryBurnQualityCountAggregate}
    >
      {({ data: aggregates, isFetching }) => {
        return (
          <BurnQualityCountAggregateByTimeChart
            hideAverageGoodInPercent={true}
            showBurnQualityCount={props.showBurnQualityCount}
            showGoodInPercent={props.showGoodInPercent}
            showRawGoodInPercent={false}
            interval={timeframeProps.timeframe}
            timeUnit={expandedTimeUnit}
            aggregates={aggregates}
            loading={isFetching}
            layout={layout}
            sx={{ flexGrow: 1, minHeight: 0 }}
          />
        );
      }}
    </BurnQualityCountAggregateByTimeLoader>
  );
};
