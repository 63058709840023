import { CSSProperties, FC } from "react";

export interface MascotThumbsDownProps {
  mode: "light" | "dark";
  style?: CSSProperties;
}

export const MascotThumbsDown: FC<MascotThumbsDownProps> = (props) => {
  const hairColor = props.mode === "dark" ? "#fff" : "#fff";
  return (
    <svg
      version="1.1"
      viewBox={"0 0 440.35 393.56"}
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
      className={"MascotThumbsDown"}
    >
      <defs>
        <style>{`
      .MascotThumbsDown.cls-1 {
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2.836px;
      }

      .MascotThumbsDown.cls-1, .MascotThumbsDown.cls-2 {
        fill: none;
      }

      .MascotThumbsDown.cls-1, .MascotThumbsDown.cls-3 {
        stroke: #000;
      }

      .MascotThumbsDown.cls-4 {
        fill: #94c8c8;
      }

      .MascotThumbsDown.cls-5 {
        fill: #9cc;
      }

      .MascotThumbsDown.cls-2 {
        stroke: #211e1f;
        stroke-width: 4px;
      }

      .MascotThumbsDown.cls-2, .MascotThumbsDown.cls-3 {
        stroke-miterlimit: 10;
      }

      .MascotThumbsDown.cls-6 {
        fill: #98cbcb;
      }

      .MascotThumbsDown.cls-7 {
        fill: #e1f1ef;
      }

      .MascotThumbsDown.cls-8 {
        fill: #211e1f;
      }

      .MascotThumbsDown.cls-3 {
        stroke-width: .03445px;
      }

      .MascotThumbsDown.cls-9 {
        fill: #fff;
      }

          `}</style>
      </defs>
      <path
        className="MascotThumbsDown cls-7"
        d="m255.83 57.225c-5.3838-39.198-55.978-57.915-86.579-33.92-16.477 15.831-17.346-8.784-32.477-13.314-12.946-2.6194-19.579 12.811-32.297 9.9844-11.123-2.4843-10.461-15.248-21.516-17.527-12.285-2.5331-16.823 9.1276-32.41 9.9372-10.738 0.55779-16.354-16.568-33.185-11.417-34.301 10.497-13.316 58.379 24.069 38.904 4.3872-2.2853 9.4439-6.7722 15.603-5.9019 10.644 1.5041 11.702 14.798 22.366 17.848 12.238 3.5005 18.368-11.854 32.298-9.9865 15.072 2.0208 16.355 20.625 31.271 22.996 12.763 2.0291 18.917-12.717 31.224-10.165 11.415 1.9411 13.523 19.176 17.664 27.556 19.882 44.364 72.182 18.891 63.968-24.995z"
      />
      <rect
        className="MascotThumbsDown cls-4"
        transform="translate(658.16 467.88) rotate(185.82)"
        x="287.6"
        y="202.78"
        width="106.73"
        height="28.869"
      />
      <path
        className="MascotThumbsDown cls-5"
        d="m224 388.6-14.147 0.64308-5.0291 0.22959-88.667 4.0876 18.05-31.645 53.439 11.436-45.309-48.915c6.1084-3.9728 12.263-7.9229 18.372-11.896 0.09195-0.04597 0.16091-0.1148 0.2523-0.16077 7.5555-4.8915 37.318-24.825 44.369-29.395 10.173 0.55113 10.701 6.5909 17.316 11.919-5.5808 4.0188-20.922 13.32-26.755 17.476-0.09195 0.04597-0.16035 0.1148-0.22875 0.16077-7.3032 5.2359-14.583 10.518-21.886 15.754l38.144 45.815 3.17 3.8121 8.91 10.679z"
      />
      <path
        className="MascotThumbsDown cls-3"
        d="m222.65 294.91c-5.5808 4.0188-20.922 13.32-26.755 17.476h-34.929c7.5555-4.8915 37.318-24.825 44.369-29.395 10.173 0.55113 10.701 6.5909 17.316 11.919z"
      />
      <path
        className="MascotThumbsDown cls-5"
        d="m228.95 388.6 14.147 0.64308 5.0291 0.22959 88.667 4.0876-18.05-31.645-53.439 11.436 45.309-48.915c-6.1084-3.9728-12.263-7.9229-18.372-11.896-0.09195-0.04597-0.16091-0.1148-0.2523-0.16077-7.5555-4.8915-37.318-24.825-44.369-29.395-10.173 0.55113-10.701 6.5909-17.316 11.919 5.5808 4.0188 20.922 13.32 26.755 17.476 0.09195 0.04597 0.16035 0.1148 0.22875 0.16077 7.3032 5.2359 14.583 10.518 21.886 15.754l-38.144 45.815-3.17 3.8121-8.91 10.679z"
      />
      <path
        className="MascotThumbsDown cls-3"
        d="m230.3 294.91c5.5808 4.0188 20.922 13.32 26.755 17.476h34.929c-7.5555-4.8915-37.318-24.825-44.369-29.395-10.173 0.55113-10.701 6.5909-17.316 11.919z"
      />
      <path
        className="MascotThumbsDown cls-6"
        d="m132.27 115.12h188.42v165.57c0 12.611-10.239 22.85-22.85 22.85h-142.72c-12.611 0-22.85-10.239-22.85-22.85v-165.57z"
      />
      <rect
        className="MascotThumbsDown cls-6"
        x="98.611"
        y="94.777"
        width="255.73"
        height="49.298"
      />
      <rect
        x="185.29"
        y="152.65"
        width="81.167"
        height="7.4786"
        rx="3.7393"
        ry="3.7393"
      />
      <path
        className="MascotThumbsDown cls-2"
        d="m208.86 232.86c14.028-5.1898 28.056-10.38 42.084-15.569"
      />
      <path
        className="MascotThumbsDown cls-9"
        d="m269.26 191.35c0 3.7485-1.0604 7.2552-2.8927 10.232h-33.28c-1.8324-2.9764-2.8927-6.4831-2.8927-10.232 0-10.79 8.7434-19.533 19.533-19.533 10.79 0 19.533 8.7432 19.533 19.533z"
      />
      <rect
        className="MascotThumbsDown cls-9"
        x="186.26"
        y="185.32"
        width="39.996"
        height="12.548"
        rx="4.66"
        ry="4.66"
      />
      <circle
        className="MascotThumbsDown cls-8"
        cx="247.64"
        cy="191.82"
        r="4.6507"
      />
      <circle
        className="MascotThumbsDown cls-8"
        cx="203.7"
        cy="191.82"
        r="4.6507"
      />
      <line
        className="MascotThumbsDown cls-9"
        x1="392.58"
        x2="416.13"
        y1="257.41"
        y2="262.71"
      />
      <path
        className="MascotThumbsDown cls-9"
        d="m434.24 210.28c1.6736-2.1635 1.2665-5.4098-0.27898-7.6665-1.5454-2.2568-3.9876-3.7188-6.4204-4.9689-7.6509-3.9314-15.957-6.4623-24.374-8.2373-4.1591-0.87716-8.4532-1.5755-12.645-0.86752-6.4416 1.0882-11.985 5.4592-15.722 10.818-3.737 5.3584-5.8732 11.659-7.5941 17.961-2.0114 7.3663-3.5267 14.922-3.6068 22.558-0.05757 5.4885 0.87619 11.488 4.9256 15.194 0.87134 0.79731 1.8718 1.4723 2.8234 2.1863 0.84195 0.63171 0.99142 1.7879 0.95559 2.84-0.24488 7.1872-0.48987 14.374-0.73486 21.562-0.09275 2.7209-0.12994 5.6467 1.3928 7.9037 1.4554 2.1573 4.1696 3.2719 6.7699 3.1663 2.6002-0.10551 5.0689-1.3069 7.0685-2.9723 4.246-3.5362 6.4788-9.0449 7.0846-14.537 0.60572-5.4923-0.28379-12.374-1.304-17.805l6.231 3.1032 20.87 2.3238c4.6186-2.5461 9.2371-5.0921 13.856-7.6382 1.8152-8.9808 2.8064-20.46 0.49296-33.292-0.45672-2.5333-1.0108-4.9445-1.6328-7.2299"
      />
      <path
        className="MascotThumbsDown cls-9"
        d="m435.28 230.03c2.4862-2.9174 4.0027-6.7438 3.7568-10.569-0.24573-3.8251-2.3867-7.5732-5.7679-9.3786-1.4247-0.76077-3.0068-1.1677-4.5716-1.568"
      />
      <path
        className="MascotThumbsDown cls-9"
        d="m435.06 250.43c3.2187-3.4222 4.5073-8.5501 3.2887-13.087-1.2186-4.5372-4.903-8.3294-9.4032-9.6784"
      />
      <path
        className="MascotThumbsDown cls-9"
        d="m425.72 246.91c4.0045 0.24582 7.927 2.162 10.394 5.3255 2.4674 3.1637 3.3615 7.5569 2.1004 11.366-0.30695 0.92682-0.74146 1.827-1.3992 2.5486-0.92551 1.0156-2.219 1.6052-3.4913 2.1241-2.653 1.0822-5.3829 1.9753-8.1624 2.6706-2.6662 0.6669-5.4048 1.1538-8.1506 1.0345-2.8469-0.12373-5.6245-0.89485-8.3358-1.7716-2.7335-0.88376-5.4921-1.915-7.6912-3.7634-2.1992-1.8485-3.7682-4.6747-3.418-7.5259"
      />
      <line
        className="MascotThumbsDown cls-1"
        x1="392.45"
        x2="416"
        y1="257.01"
        y2="262.32"
      />
      <path
        className="MascotThumbsDown cls-1"
        d="m434.11 209.88c1.6736-2.1635 1.2665-5.4097-0.27898-7.6665-1.5454-2.2568-3.9876-3.7189-6.4204-4.969-7.6509-3.9313-15.957-6.4623-24.374-8.2373-4.1591-0.87714-8.4533-1.5755-12.645-0.8675-6.4416 1.0881-11.985 5.4592-15.722 10.818-3.737 5.3584-5.8733 11.659-7.5941 17.961-2.0114 7.3663-3.5267 14.922-3.6068 22.558-0.05756 5.4885 0.87619 11.488 4.9256 15.194 0.87137 0.79738 1.8718 1.4723 2.8234 2.1864 0.84199 0.63174 0.99148 1.7879 0.95563 2.84-0.24489 7.1872-0.48988 14.374-0.73489 21.562-0.09272 2.7209-0.12993 5.6467 1.3928 7.9036 1.4554 2.1573 4.1696 3.2719 6.7699 3.1664 2.6002-0.10554 5.0689-1.3069 7.0685-2.9723 4.246-3.5362 6.4788-9.0449 7.0846-14.537 0.60576-5.4923-0.28378-12.374-1.3039-17.805"
      />
      <path
        className="MascotThumbsDown cls-1"
        d="m428.56 208.12c1.5648 0.40028 3.1469 0.80718 4.5716 1.568 3.3812 1.8054 5.5221 5.5535 5.7679 9.3786 0.24583 3.8252-1.2706 7.6517-3.7569 10.569"
      />
      <path
        className="MascotThumbsDown cls-1"
        d="m428.82 227.27c4.5002 1.349 8.1846 5.1412 9.4032 9.6784 1.2186 4.5372-0.07001 9.6651-3.2886 13.087"
      />
      <path
        className="MascotThumbsDown cls-1"
        d="m425.59 246.51c4.0045 0.24586 7.927 2.162 10.394 5.3256 2.4673 3.1636 3.3615 7.5569 2.1003 11.366-0.44369 1.34-1.2884 2.3993-1.3992 2.5486-1.486 2.0024-3.2789 2.9432-4.4901 3.5616-0.56577 0.28889-2.1712 1.0678-5.3686 1.7017-4.7302 0.93764-8.555 0.68744-9.9456 0.56592-0.79246-0.06926-4.1241-0.40992-8.3358-1.7716-2.7335-0.88379-5.4921-1.9151-7.6912-3.7634-0.9516-0.79979-2.877-3.202-3.418-7.5259"
      />
    </svg>
  );
};
