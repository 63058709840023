import { useEffect, useMemo, useState } from "react";
import {
  FlueMetricNaturalKey,
  FlueMetricNaturalKeyImpl,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import {
  DictionarySetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { DateTime } from "luxon";
import { Flue } from "../Flue";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";

export const useFlueMetricTimeItems = (
  storagePrefix: string,
  flue: Flue,
  defaultValue: FlueMetricNaturalKey
): {
  flueMetricTimeItems: Array<FlueMetricNaturalKey>;
  selectedFlueMetricTimeItem: FlueMetricNaturalKey;
  handleFlueMetricTimeItemSelect: (item: FlueMetricNaturalKey) => void;
} => {
  const now = useDateTimeNow();
  const [flueMetricTimeItems, setFlueMetricTimeItems] = useState<
    Array<FlueMetricNaturalKey>
  >([]);
  const [selectedFlueMetricTimeItem, setSelectedFlueMetricTimeItem] =
    useUserSettingWithDefault<FlueMetricNaturalKey>(
      storagePrefix + ".selectedFlueMetricTimeItem",
      DictionarySetting,
      defaultValue,
      {
        storeLocally: true,
        serialize: (value: FlueMetricNaturalKey): string => {
          return `${value.unit}:${value.time?.toISO() ?? ""}`;
        },
        deserialize: (string): FlueMetricNaturalKey => {
          const indexOfColon = string.indexOf(":");
          if (indexOfColon < 0) {
            throw new IllegalStateError(
              "UserSetting KeyFiguresPage.selectedFlueMetricTimeItem is not serialized on expected format: " +
                string
            );
          }
          const unitAsString = string.substring(
            0,
            indexOfColon
          ) as keyof typeof FlueMetricsTimeUnitEnum;
          const unit: FlueMetricsTimeUnitEnum | undefined =
            FlueMetricsTimeUnitEnum[unitAsString];
          if (unit === undefined) {
            throw new IllegalStateError(
              "UserSetting KeyFiguresPage.selectedFlueMetricTimeItem is not serialized on expected format: " +
                string
            );
          }
          const timeAsString = string.substring(indexOfColon + 1);
          const time =
            timeAsString.length > 0
              ? DateTime.fromISO(timeAsString)
              : undefined;
          if (time !== undefined && !time.isValid) {
            throw new IllegalStateError(
              "UserSetting KeyFiguresPage.selectedFlueMetricTimeItem is not serialized on expected format: " +
                string
            );
          }
          return new FlueMetricNaturalKeyImpl(flue.id, unit, time);
        },
      }
    );

  useEffect(() => {
    const items: Array<FlueMetricNaturalKey> = [];
    items.push(FlueMetricNaturalKeyImpl.ever(flue.id));
    if (flue.fields.lastSweepDate != null) {
      items.push(FlueMetricNaturalKeyImpl.sinceSweep(flue.id));
    }

    const yearFlueMetrics = flue.metrics
      .filter(
        (metric) =>
          metric.timeUnit === FlueMetricsTimeUnitEnum.Year &&
          metric.time !== undefined
      )
      .sort((a, b) => {
        const aTime = a.time ?? _throw(new IllegalStateError());
        const bTime = b.time ?? _throw(new IllegalStateError());
        return aTime.equals(bTime) ? 0 : aTime < bTime ? 1 : -1;
      });

    yearFlueMetrics.forEach((metric) => {
      items.push(metric.naturalKey);
    });

    const currentYear = DateTime.local(now.year, 1, 1) as DateTime<true>;
    if (
      yearFlueMetrics.find((it) => it.time?.equals(currentYear)) === undefined
    ) {
      items.unshift(FlueMetricNaturalKeyImpl.year(flue.id, currentYear));
    }

    const monthFlueMetrics = flue.metrics
      .filter(
        (metric) =>
          metric.timeUnit === FlueMetricsTimeUnitEnum.Month &&
          metric.time !== undefined
      )
      .sort((a, b) => {
        const aTime = a.time ?? _throw(new IllegalStateError());
        const bTime = b.time ?? _throw(new IllegalStateError());
        return aTime.equals(bTime) ? 0 : aTime < bTime ? 1 : -1;
      });

    monthFlueMetrics.forEach((metric) => {
      items.push(metric.naturalKey);
    });

    const currentMonth = DateTime.local(
      now.year,
      now.month,
      1
    ) as DateTime<true>;
    if (
      monthFlueMetrics.find((it) => it.time?.equals(currentMonth)) === undefined
    ) {
      items.unshift(FlueMetricNaturalKeyImpl.month(flue.id, currentMonth));
    }

    setFlueMetricTimeItems(items);
  }, [flue, now.month, now.year]);

  return useMemo(() => {
    return {
      flueMetricTimeItems: flueMetricTimeItems,
      selectedFlueMetricTimeItem: selectedFlueMetricTimeItem,
      handleFlueMetricTimeItemSelect: setSelectedFlueMetricTimeItem,
    };
  }, [
    flueMetricTimeItems,
    selectedFlueMetricTimeItem,
    setSelectedFlueMetricTimeItem,
  ]);
};
