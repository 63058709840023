import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  LinearProgress,
  Slide,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import {
  BuildingSelect,
  FlueSelect,
} from "@airmont/firefly/my-chimney/ts/building";
import { PeriodSelect } from "./periodSelect/PeriodSelect";
import { BurnInfo } from "./BurnInfo";
import { BurnTimeSeriesChart } from "./BurnTimeSeriesChart";
import {
  AddressId,
  Burn,
  BurnId,
  FlueId,
} from "@airmont/firefly/shared/ts/domain";
import { useResizeDetector } from "react-resize-detector";
import { SizeClass, SizeClassComparer } from "@airmont/shared/ts/ui/responsive";
import { useMyChimneyContext } from "../MyChimneyContext";
import { TimeframeProps } from "@airmont/shared/ts/ui/timeframe";
import { BurnList } from "./BurnList";
import { TimeAxisMaxDuration } from "./TimeAxisMaxDuration";
import { defaultTimeAxisDuration } from "./defaultTimeAxisDuration";
import { Duration } from "luxon";
import { If, With } from "@airmont/shared/ts/ui/react";
import { MascotSleeping } from "@airmont/firefly/my-chimney/ts/shared";
import { useTranslation } from "react-i18next";
import { BurnsLoadingResult } from "@airmont/firefly/my-chimney/ts/burn";

export interface BurnsPageContentProps {
  layout: SizeClass;
  timeframeProps: TimeframeProps;
  burnsLoadingResult: BurnsLoadingResult;
  timeAxisDomain: {
    maxDuration: TimeAxisMaxDuration;
  };
}

export const BurnsPageContent: FC<BurnsPageContentProps> = (props) => {
  const { timeframeProps, burnsLoadingResult } = props;
  const {
    burns,
    burnsStatus,
    burnsFetchStatus,
    burnsAreLoading,
    burnsAreFetching,
    burnTemperatureSeries,
    burnTemperatureSeriesStatus,
    burnTemperatureSeriesFetchStatus,
    burnTemperatureSeriesAreLoading,
    burnTemperatureSeriesAreFetching,
    isAnonymizedTime,
  } = burnsLoadingResult;
  const theme = useTheme();
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const layout = props.layout;
  const sizeComparer = new SizeClassComparer(layout);
  const { width, ref } = useResizeDetector({ handleHeight: false });
  const {
    buildings,
    selectedBuilding,
    selectedFlue,
    setSelectedBuilding,
    setSelectedFlue,
  } = useMyChimneyContext();
  console.log(`bpc BurnsPageContent: *********************************`);
  console.log(
    `bpc burnsStatus: ${burnsStatus}, burnsFetchStatus: ${burnsFetchStatus}`
  );
  console.log(
    `bpc isBurnsLoading: ${burnsAreLoading}, isBurnsFetching: ${burnsAreFetching}`
  );
  console.log("bpc burns: " + burns?.length);
  console.log(
    `bpc burnTemperatureSeriesStatus: ${burnTemperatureSeriesStatus}, burnTemperatureSeriesFetchStatus: ${burnTemperatureSeriesFetchStatus}`
  );
  console.log(
    `bpc isTemperatureSeriesLoading: ${burnTemperatureSeriesAreLoading}, isTemperatureSeriesFetching: ${burnTemperatureSeriesAreFetching}`
  );

  console.log("bpc temperatureSeries: " + burnTemperatureSeries?.length);
  const [selectedBurn, setSelectedBurn] = useState<Burn | undefined>(undefined);
  const selectedBurnIds = useMemo(() => {
    return selectedBurn !== undefined ? [selectedBurn.id] : emptyArrayOfBurnIds;
  }, [selectedBurn]);

  const [forceAnonymizeTime, setForceAnonymizeTime] = useState<boolean>(false);
  useEffect(() => {
    if (burns != null && burns.length > 0) {
      setSelectedBurn(burns[0]);
    } else {
      setSelectedBurn(undefined);
    }
  }, [burns]);
  const handleSelectedBurn = useCallback((burn: Burn) => {
    setSelectedBurn(burn);
  }, []);
  const handleBuildingChange = useCallback(
    (value: AddressId) => {
      setSelectedBuilding(value);
    },
    [setSelectedBuilding]
  );
  const handleSelectedFlue = useCallback(
    (flue: FlueId) => {
      setSelectedFlue(flue);
    },
    [setSelectedFlue]
  );

  const handleAnonymizeBurnsClick = () => {
    setForceAnonymizeTime(!forceAnonymizeTime);
  };

  const selectedTemperatureSeries = useMemo(() => {
    return burnTemperatureSeries?.filter(
      (it) => selectedBurn?.id === it.burnId
    );
  }, [selectedBurn?.id, burnTemperatureSeries]);

  const timeAxisMaxDuration = useMemo(() => {
    let timeAxisDomainMax: Duration | undefined = undefined;
    if (props.timeAxisDomain.maxDuration === "default") {
      timeAxisDomainMax = defaultTimeAxisDuration;
    } else if (props.timeAxisDomain.maxDuration instanceof Duration) {
      timeAxisDomainMax = props.timeAxisDomain.maxDuration;
    } else if (props.timeAxisDomain.maxDuration === "all") {
      return undefined;
    }
    return timeAxisDomainMax;
  }, [props.timeAxisDomain.maxDuration]);

  return (
    <Stack
      direction={"column"}
      gap={1}
      useFlexGap
      sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
      ref={ref}
    >
      <Toolbar
        disableGutters
        variant={"dense"}
        sx={{
          justifyContent: layout === SizeClass.ExtraLarge ? "start" : "center",
        }}
      >
        <BuildingSelect
          buildings={buildings}
          selected={selectedBuilding.id}
          onChange={handleBuildingChange}
        />
        {selectedBuilding.getNumberOfFlues() > 1 && (
          <FlueSelect
            building={selectedBuilding}
            selectedFlue={selectedFlue}
            onSelectFlue={handleSelectedFlue}
            sx={{ ml: 1 }}
          />
        )}
      </Toolbar>
      <PeriodSelect
        timeframe={timeframeProps}
        flue={selectedFlue}
        color={"inherit"}
        sx={{ maxWidth: width ?? 100 - 100, minHeight: "fit-content" }}
      />
      <If
        expression={burnsAreFetching}
        then={<LinearProgress color={"secondary"} sx={{ height: "1px" }} />}
        else={<Divider />}
      />
      <BurnList
        burns={burns}
        loading={burnsAreLoading}
        fetching={burnsAreFetching}
        selectedBurn={selectedBurn}
        onSelected={handleSelectedBurn}
      />
      {selectedBurn !== undefined && <Divider />}
      <Slide
        direction={"left"}
        in={selectedBurn !== undefined}
        mountOnEnter
        unmountOnExit
      >
        <Box sx={{ minHeight: "24px" }}>
          {selectedBurn !== undefined && (
            <BurnInfo
              burn={selectedBurn}
              variant={"normal"}
              layout={layout}
              hideDate={sizeComparer.lte(SizeClass.Medium)}
              hideEndDate={sizeComparer.eq(SizeClass.Compact)}
            />
          )}
        </Box>
      </Slide>
      {(burns?.length ?? 0) > 0 && <Divider />}
      <Box
        sx={{
          flexGrow: 1,
          minHeight: "296px",
          minWidth: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
          position: "relative",
          pl: layout !== SizeClass.Compact ? 1 : 0,
          backgroundColor: layout === SizeClass.Compact ? "unset" : undefined,
          boxShadow: layout === SizeClass.Compact ? "unset" : undefined,
        }}
      >
        {burnTemperatureSeriesAreFetching && (
          <LinearProgress
            color={"secondary"}
            sx={{
              position: "absolute",
              height: "2px",
              top: "1px",
              left: theme.shape.borderRadius,
              right: theme.shape.borderRadius,
            }}
          />
        )}
        <With>
          {() => {
            if (
              burnTemperatureSeries != null &&
              burnTemperatureSeries.length > 0
            ) {
              return (
                <BurnTimeSeriesChart
                  flue={selectedFlue}
                  burnTimeSeries={burnTemperatureSeries}
                  anonymizedTime={isAnonymizedTime || forceAnonymizeTime}
                  selected={selectedBurnIds}
                  selectedMonth={timeframeProps.timeframe.start}
                  layout={layout}
                  timeAxis={{ maxDuration: timeAxisMaxDuration }}
                  sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
                />
              );
            } else if (burnTemperatureSeriesAreLoading) {
              return <CircularProgress sx={{ alignSelf: "center" }} />;
            } else if (
              burnTemperatureSeries !== undefined &&
              burnTemperatureSeries.length === 0
            ) {
              return (
                <Stack useFlexGap gap={4} sx={{ alignItems: "center" }}>
                  <MascotSleeping
                    mode={theme.palette.mode}
                    style={{ maxWidth: "250px" }}
                  />
                  <Typography align={"center"}>
                    {t("There are no burns registered for this period")}
                  </Typography>
                </Stack>
              );
            } else {
              return null;
            }
          }}
        </With>
      </Box>
    </Stack>
  );
};

const emptyArrayOfBurnIds: Array<BurnId> = [];
