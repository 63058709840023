import { FC, useMemo } from "react";
import {
  BurnQualityCountAggregateByTimeLoader,
  BurnQualityCountDataPoints,
  FlueMetricNaturalKey,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import { useBurnDao } from "@airmont/firefly/my-chimney/ts/burn";
import { DateTimeUnit, Duration } from "luxon";
import { MathUtils, notNull, notUndef } from "@airmont/shared/ts/utils/core";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { useConvertFlueMetricNaturalKeyToTimeframeProps } from "./useConvertFlueMetricNaturalKeyToTimeframeProps";

const dateTimeNowRefreshDuration = Duration.fromDurationLike({ hours: 6 });

export interface BurnQualityPercentLoaderProps {
  customerEnvironment: CustomerEnvironmentId;
  flue: Flue;
  selectedFlueMetric: FlueMetricNaturalKey;
  children: (result: { data: number | undefined }) => React.ReactNode;
}

export const BurnQualityPercentLoader: FC<BurnQualityPercentLoaderProps> = (
  props
) => {
  const { customerEnvironment, flue, selectedFlueMetric } = props;
  const burnDao = useBurnDao({
    customerEnvironment: customerEnvironment,
  });
  const now = useDateTimeNow(dateTimeNowRefreshDuration);
  const timeframeProps = useConvertFlueMetricNaturalKeyToTimeframeProps({
    flueMetricTimeItem: selectedFlueMetric,
    now: now,
  });

  const aggregateBy: DateTimeUnit | undefined = useMemo(() => {
    return untranslatableFlueMetricTimeUnits.includes(
      props.selectedFlueMetric.unit
    )
      ? undefined
      : props.selectedFlueMetric.unit === FlueMetricsTimeUnitEnum.Ever ||
        props.selectedFlueMetric.unit === FlueMetricsTimeUnitEnum.Year
      ? "month"
      : (props.selectedFlueMetric.unit.toLowerCase() as unknown as DateTimeUnit);
  }, [props.selectedFlueMetric.unit]);

  return (
    <>
      {timeframeProps != null && aggregateBy !== undefined ? (
        <BurnQualityCountAggregateByTimeLoader
          flueId={flue.id}
          start={flue.audit.created.startOf(aggregateBy)}
          end={now}
          aggregateBy={aggregateBy}
          queryBurnQualityCountAggregate={
            burnDao.queryBurnQualityCountAggregate
          }
        >
          {(result) => {
            const dataPoints = notUndef(result.data, (data) =>
              BurnQualityCountDataPoints.fromAggregates(data)
            );

            const dataPoint = notUndef(dataPoints, (dataPoints) => {
              if (selectedFlueMetric.unit === FlueMetricsTimeUnitEnum.Ever) {
                return dataPoints.lastWithAssessedIgnitions();
              }
              if (selectedFlueMetric.unit === FlueMetricsTimeUnitEnum.Year) {
                return notUndef(selectedFlueMetric.time, (time) =>
                  dataPoints.lastOfYear(time.year)
                );
              }
              return notUndef(selectedFlueMetric.time, (it) =>
                dataPoints.findByTime(it)
              );
            });


            return props.children({
              data: notUndef(
                dataPoint,
                (it) =>
                  notNull(it.goodInPercent, (it) => MathUtils.round(it)) ??
                  undefined
              ),
            });
          }}
        </BurnQualityCountAggregateByTimeLoader>
      ) : (
        props.children({ data: undefined })
      )}
    </>
  );
};

const untranslatableFlueMetricTimeUnits = [
  FlueMetricsTimeUnitEnum.SinceSweep,
  FlueMetricsTimeUnitEnum.AtSweep,
];
