import {
  BurnId,
  BurnTemperatureSeries,
  BurnTemperatureSeriesImpl,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import React, { FC, useMemo } from "react";
import {
  TimeSeries,
  TimeSeriesImpl,
} from "@airmont/firefly/shared/ts/timeseries";
import { alpha, SxProps, useTheme } from "@mui/material";
import {
  TimeAxisValueFormatterFactory,
  TimeSeriesMuiChart,
  TimeSeriesMuiChartContainer,
} from "@airmont/firefly/shared/ts/timeseries-mui-chart";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { ChartsReferenceLine } from "@mui/x-charts";
import { useResolveMaxTemperatureReferenceLineProps } from "./useResolveMaxTemperatureReferenceLineProps";
import { DateTime, Duration } from "luxon";
import { XAxisDefaultConfig, YAxisDefaultConfig } from "shared-ts-mui";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { interpolateRdYlBu } from "d3-scale-chromatic";

const unit = "°C";

export interface BurnTimeSeriesSensitiveChartProps {
  flue: Flue;
  burnTimeSeries?: Array<BurnTemperatureSeries>;
  selected: Array<BurnId>;
  selectedMonth?: DateTime;
  timeAxis?: {
    maxDuration?: Duration | undefined;
  };
  layout?: SizeClass;
  sx?: SxProps;
}

export const BurnTimeSeriesSensitiveChart: FC<
  BurnTimeSeriesSensitiveChartProps
> = (props) => {
  const { flue, burnTimeSeries, selected, selectedMonth } = props;
  const theme = useTheme();
  const layout = props.layout ?? SizeClass.ExtraLarge;
  const flueMetricsSinceStart = flue.querySingleMetricsOrUndef(
    FlueMetricsTimeUnitEnum.Ever
  );

  const maxTemperatureSinceStart =
    flueMetricsSinceStart?.metrics.temperatureMax;

  const maxY = useMemo(() => {
    return maxTemperatureSinceStart ?? 100;
  }, [maxTemperatureSinceStart]);

  const chartYReferenceLineProps = useResolveMaxTemperatureReferenceLineProps(
    flue,
    unit,
    selectedMonth
  );

  const timeSeriesArray: Array<TimeSeries<number | null>> | undefined =
    useMemo(() => {
      return burnTimeSeries
        ?.filter((it) => selected.includes(it.burnId))
        .map((burnSeries) => {
          return new TimeSeriesImpl({
            info: {
              id: `${burnSeries.burnId}`,
              sid: flue.id,
              name: "",
              resolution: Duration.fromMillis(1000 * 60).toISO(),
              unit: unit,
            },
            points: burnSeries.dataPoints,
          });
        });
    }, [burnTimeSeries, selected, flue.id]);

  const yAxisConfig: Array<YAxisDefaultConfig> = useMemo(() => {
    const minY =
      burnTimeSeries !== undefined
        ? BurnTemperatureSeriesImpl.getMinY(burnTimeSeries, 0)
        : 0;

    return [
      {
        max: maxY,
        min: minY,
        colorMap: {
          type: "continuous",
          min: minY,
          max: maxY,
          color: (value) => interpolateRdYlBu(1 - (0.5 + 0.5 * value)),
        },
      } as YAxisDefaultConfig,
    ];
  }, [burnTimeSeries, maxY]);

  const xAxisMax = useMemo(() => {
    if (
      timeSeriesArray == null ||
      timeSeriesArray.length === 0 ||
      props.timeAxis?.maxDuration === undefined
    ) {
      return undefined;
    }

    if (timeSeriesArray.length > 1) {
      throw new Error("Viewing more than one time series is not supported yet");
    }

    return timeSeriesArray[0].firstPoint?.time.plus(
      props.timeAxis?.maxDuration
    );
  }, [props.timeAxis?.maxDuration, timeSeriesArray]);

  const ignitionEndTime = useMemo(() => {
    if (timeSeriesArray == null || timeSeriesArray.length === 0) {
      return undefined;
    }

    if (timeSeriesArray.length > 1) {
      throw new Error("Viewing more than one time series is not supported yet");
    }

    return timeSeriesArray[0].firstPoint?.time.plus({ minutes: 15 });
  }, [timeSeriesArray]);

  const xAxisConfig: XAxisDefaultConfig | undefined = useMemo(() => {
    return {
      max: xAxisMax?.toMillis(),
      valueFormatter: TimeAxisValueFormatterFactory.create({
        formatOptions: DateTime.TIME_24_SIMPLE,
      }),
      domainLimit: "strict",
    } as XAxisDefaultConfig;
  }, [xAxisMax]);

  return (
    <TimeSeriesMuiChartContainer sx={props.sx}>
      <TimeSeriesMuiChart
        timeSeries={timeSeriesArray ?? []}
        yAxisConfig={yAxisConfig}
        xAxisConfig={xAxisConfig}
        margin={
          layout === SizeClass.Compact
            ? {
                left: 50,
                right: 20,
                top: 20,
                bottom: 30,
              }
            : undefined
        }
        sx={{
          ".MuiLineElement-root": {
            strokeWidth: 3,
            filter: "drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.9))",
          },
        }}
      >
        {ignitionEndTime != null && (
          <ChartsReferenceLine
            x={ignitionEndTime?.toMillis()}
            lineStyle={{
              stroke: alpha(theme.palette.divider, 0.2),
            }}
          />
        )}
        {chartYReferenceLineProps.map((it) => {
          return <ChartsReferenceLine key={it.label} {...it} />;
        })}
      </TimeSeriesMuiChart>
    </TimeSeriesMuiChartContainer>
  );
};
