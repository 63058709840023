import { CSSProperties, FC } from "react";

export interface MascotThumbsUpAndStarProps {
  mode: "light" | "dark";
  style?: CSSProperties;
}

export const MascotThumbsUpAndStar: FC<MascotThumbsUpAndStarProps> = (
  props
) => {
  const hairColor = props.mode === "dark" ? "#fff" : "#fff";

  return (
    <svg
      version="1.1"
      viewBox={"0 0 447.76 392.57"}
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
      className={"MascotThumbsUpAndStar"}
    >
      <defs>
        <style>{`
      .MascotThumbsUpAndStar.cls-1 {
        fill: #94c8c8;
      }

      .MascotThumbsUpAndStar.cls-2 {
        fill: #9cc;
      }

      .MascotThumbsUpAndStar.cls-3 {
        fill: #47af6e;
      }

      .MascotThumbsUpAndStar.cls-4 {
        fill: #98cbcb;
      }

      .MascotThumbsUpAndStar.cls-5 {
        fill: #e1f1ef;
      }

      .MascotThumbsUpAndStar.cls-6 {
        fill: #211e1f;
      }

      .MascotThumbsUpAndStar.cls-7 {
        stroke: #000;
        stroke-width: .03px;
      }

      .MascotThumbsUpAndStar.cls-7, .MascotThumbsUpAndStar.cls-8, .MascotThumbsUpAndStar.cls-9, .MascotThumbsUpAndStar.cls-10 {
        stroke-miterlimit: 10;
      }

      .MascotThumbsUpAndStar.cls-11, .MascotThumbsUpAndStar.cls-9 {
        fill: #fff;
      }

      .MascotThumbsUpAndStar.cls-8 {
        stroke: #211e1f;
        stroke-width: 3.01px;
      }

      .MascotThumbsUpAndStar.cls-8, .MascotThumbsUpAndStar.cls-10 {
        fill: none;
      }

      .MascotThumbsUpAndStar.cls-9, .MascotThumbsUpAndStar.cls-10 {
        stroke: #221f1f;
        stroke-width: 2.84px;
      }
          `}</style>
      </defs>
      <path
        className="MascotThumbsUpAndStar cls-5"
        d="m255.83 57.227c-5.38-39.2-55.98-57.92-86.58-33.92-16.48 15.83-17.35-8.78-32.48-13.31-12.95-2.62-19.58 12.81-32.3 9.98-11.12-2.48-10.46-15.25-21.52-17.53-12.29-2.53-16.82 9.13-32.41 9.94-10.74 0.56-16.35-16.57-33.19-11.42-34.28 10.5-13.3 58.39 24.09 38.91 4.39-2.29 9.44-6.77 15.6-5.9 10.64 1.5 11.7 14.8 22.37 17.85 12.24 3.5 18.37-11.85 32.3-9.99 15.07 2.02 16.35 20.62 31.27 23 12.76 2.03 18.92-12.72 31.22-10.16 11.41 1.94 13.52 19.18 17.66 27.56 19.88 44.36 72.18 18.89 63.97-24.99l-2e-5 -0.02z"
      />
      <rect
        className="MascotThumbsUpAndStar cls-1"
        transform="translate(-52.45 126.55) rotate(-19.64)"
        x="285.99"
        y="200.35"
        width="106.73"
        height="28.87"
      />
      <path
        className="MascotThumbsUpAndStar cls-9"
        d="m419.31 159.85c-9.98 1.44-16.71 6.1-17.02 11-0.14001 2.28 1.12 4.13 1.67 4.93 3.42 5 10.2 5.51 17.23 6.03 6.78 0.5 13.69 1.02 18.31-3.29 0.81-0.75999 2.96-2.76 3.5-6 0.39999-2.42-0.23999-4.6-0.88-5.86-2.74-5.41-11.61-6.19-17.37-6.62-13.2-1-18.24 2.66-21.12-0.50999-2.5-2.75-1.36-6.56 0.76999-16.62 1.29-6.08 0.23001-15.9-5.94-20.93-2.89-2.35-7.91-4.3-11.44-2.45-4.82 2.54-5.18 11.32-5.49 18.84 0 0-0.62 14.52-3.21 19.46-0.25 0.48-0.51001 0.88-0.66 1.12 0 0-0.07999 0.11-0.14999 0.23-1.18 1.69-8.72 8.58-8.72 8.58s-2.56 2.34-5.15 6.62c-1.3 2.15-3.73 7.06-2.79 19.33 1.02 13.38 1.63 21.37 7.87 27.92 5.77 6.06 13.11 7.48 21.01 9.01 11.5 2.23 21.17 0.8 26.93-0.50999"
      />
      <path
        className="MascotThumbsUpAndStar cls-9"
        d="m437.73 218.08c1.51 1.87 1.73 4.6 0.82999 6.83-0.89999 2.23-2.79 3.97-4.95 5.02s-4.59 1.46-6.99 1.56c-6.24 0.27-12.6-1.55-17.57-5.32-1.45-1.09-2.8-2.39-3.57-4.03-0.76999-1.64-0.87-3.68 0.10999-5.21 0.67999-1.06 1.82-1.78 3.01-2.19 1.2-0.41 2.47-0.55 3.72-0.67999"
      />
      <path
        className="MascotThumbsUpAndStar cls-9"
        d="m441.26 198.81c1.9 0.89 2.86 3.05 3.3 5.1 0.51999 2.46 0.51999 5.05-0.25 7.44s-2.35 4.55-4.52 5.8c-2.47 1.42-5.46 1.58-8.3 1.47-4.8-0.2-9.56-1.08-14.11-2.6-3.87-1.3-7.69-3.15-10.36-6.24-1.35-1.57-2.41-3.57-2.18-5.64 0.23999-2.27 2-3.71 2.77-4.38 2.31-1.97 4.95-2.23 6-2.28"
      />
      <path
        className="MascotThumbsUpAndStar cls-9"
        d="m439.5 178.52c1.02 0.57001 4.24 2.54 5.87 6.63 0.5 1.25 1.98 5.53-0.14001 10.01-1.87 3.94-5.33 5.61-6.6 6.07-1.78 0.64999-4 1.32-9.14 0.88-5.4-0.47-13.77-1.3-20.88-7.23-4.31-3.59-4.52-6.02-4.53-6.85-0.03-2.83 2.06-4.97 2.38-5.29 1.75-1.75 3.79-2.19 4.55-2.32"
      />
      <path
        className="MascotThumbsUpAndStar cls-10"
        d="m419.31 159.85c-9.98 1.44-16.71 6.1-17.02 11-0.14001 2.28 1.12 4.13 1.67 4.93 3.42 5 10.2 5.51 17.23 6.03 6.78 0.5 13.69 1.02 18.31-3.29 0.81-0.75999 2.96-2.76 3.5-6 0.39999-2.42-0.23999-4.6-0.88-5.86-2.74-5.41-11.61-6.19-17.37-6.62-13.2-1-18.24 2.66-21.12-0.50999-2.5-2.75-1.36-6.56 0.76999-16.62 1.29-6.08 0.23001-15.9-5.94-20.93-2.89-2.35-7.91-4.3-11.44-2.45-4.82 2.54-5.18 11.32-5.49 18.84 0 0-0.62 14.52-3.21 19.46-0.25 0.48-0.51001 0.88-0.66 1.12 0 0-0.07999 0.11-0.14999 0.23-1.18 1.69-8.72 8.58-8.72 8.58s-2.56 2.34-5.15 6.62c-1.3 2.15-3.73 7.06-2.79 19.33 1.02 13.38 1.63 21.37 7.87 27.92 5.77 6.06 13.11 7.48 21.01 9.01 11.5 2.23 21.17 0.8 26.93-0.50999"
      />
      <rect
        className="MascotThumbsUpAndStar cls-1"
        transform="translate(-127.61 248.51) rotate(-70.36)"
        x="98.03"
        y="161.4"
        width="28.87"
        height="106.73"
      />
      <path
        className="MascotThumbsUpAndStar cls-9"
        d="m32.514 159.85c9.98 1.44 16.71 6.1 17.02 11 0.14 2.28-1.12 4.13-1.67 4.93-3.42 5-10.2 5.51-17.23 6.03-6.78 0.5-13.69 1.02-18.31-3.29-0.81-0.75999-2.96-2.76-3.5-6-0.4-2.42 0.24-4.6 0.88-5.86 2.74-5.41 11.61-6.19 17.37-6.62 13.2-1 18.24 2.66 21.12-0.50999 2.5-2.75 1.36-6.56-0.77-16.62-1.29-6.08-0.23-15.9 5.94-20.93 2.89-2.35 7.91-4.3 11.44-2.45 4.82 2.54 5.18 11.32 5.49 18.84 0 0 0.62 14.52 3.21 19.46 0.25 0.48 0.51 0.88 0.66 1.12 0 0 0.08 0.11 0.15 0.23 1.18 1.69 8.72 8.58 8.72 8.58s2.56 2.34 5.15 6.62c1.3 2.15 3.73 7.06 2.79 19.33-1.02 13.38-1.63 21.37-7.87 27.92-5.77 6.06-13.11 7.48-21.01 9.01-11.5 2.23-21.17 0.8-26.93-0.50999"
      />
      <path
        className="MascotThumbsUpAndStar cls-9"
        d="m14.094 218.08c-1.51 1.87-1.73 4.6-0.83 6.83s2.79 3.97 4.95 5.02 4.59 1.46 6.99 1.56c6.24 0.27 12.6-1.55 17.57-5.32 1.45-1.09 2.8-2.39 3.57-4.03s0.87-3.68-0.11-5.21c-0.68-1.06-1.82-1.78-3.01-2.19-1.2-0.41-2.47-0.55-3.72-0.67999"
      />
      <path
        className="MascotThumbsUpAndStar cls-9"
        d="m10.564 198.81c-1.9 0.89-2.86 3.05-3.3 5.1-0.52 2.46-0.52 5.05 0.25 7.44s2.35 4.55 4.52 5.8c2.47 1.42 5.46 1.58 8.3 1.47 4.8-0.2 9.56-1.08 14.11-2.6 3.87-1.3 7.69-3.15 10.36-6.24 1.35-1.57 2.41-3.57 2.18-5.64-0.24-2.27-2-3.71-2.77-4.38-2.31-1.97-4.95-2.23-6-2.28"
      />
      <path
        className="MascotThumbsUpAndStar cls-9"
        d="m12.324 178.52c-1.02 0.57001-4.24 2.54-5.87 6.63-0.5 1.25-1.98 5.53 0.14 10.01 1.87 3.94 5.33 5.61 6.6 6.07 1.78 0.64999 4 1.32 9.14 0.88 5.4-0.47 13.77-1.3 20.88-7.23 4.31-3.59 4.52-6.02 4.53-6.85 0.03-2.83-2.06-4.97-2.38-5.29-1.75-1.75-3.79-2.19-4.55-2.32"
      />
      <path
        className="MascotThumbsUpAndStar cls-10"
        d="m32.514 159.85c9.98 1.44 16.71 6.1 17.02 11 0.14 2.28-1.12 4.13-1.67 4.93-3.42 5-10.2 5.51-17.23 6.03-6.78 0.5-13.69 1.02-18.31-3.29-0.81-0.75999-2.96-2.76-3.5-6-0.4-2.42 0.24-4.6 0.88-5.86 2.74-5.41 11.61-6.19 17.37-6.62 13.2-1 18.24 2.66 21.12-0.50999 2.5-2.75 1.36-6.56-0.77-16.62-1.29-6.08-0.23-15.9 5.94-20.93 2.89-2.35 7.91-4.3 11.44-2.45 4.82 2.54 5.18 11.32 5.49 18.84 0 0 0.62 14.52 3.21 19.46 0.25 0.48 0.51 0.88 0.66 1.12 0 0 0.08 0.11 0.15 0.23 1.18 1.69 8.72 8.58 8.72 8.58s2.56 2.34 5.15 6.62c1.3 2.15 3.73 7.06 2.79 19.33-1.02 13.38-1.63 21.37-7.87 27.92-5.77 6.06-13.11 7.48-21.01 9.01-11.5 2.23-21.17 0.8-26.93-0.50999"
      />
      <rect
        className="MascotThumbsUpAndStar cls-4"
        x="246.11"
        y="203.95"
        width="26.5"
        height="15.18"
      />
      <path
        className="MascotThumbsUpAndStar cls-2"
        d="m224 387.61-14.15 0.64001-5.03 0.23001-88.67 4.09 18.05-31.65 53.44 11.44-45.31-48.91c6.11-3.97 12.26-7.92 18.37-11.9 0.09-0.04999 0.16-0.10999 0.25-0.16 7.56-4.89 37.32-24.82 44.37-29.39 10.17 0.54999 10.7 6.59 17.32 11.92-5.58 4.02-20.92 13.32-26.75 17.48-0.09 0.04999-0.16 0.10999-0.23 0.16-7.3 5.24-14.58 10.52-21.89 15.75l38.14 45.81 3.17 3.81 8.91 10.68 0.00998-6e-5z"
      />
      <path
        className="MascotThumbsUpAndStar cls-7"
        d="m222.65 293.92c-5.58 4.02-20.92 13.32-26.75 17.48h-34.93c7.56-4.89 37.32-24.82 44.37-29.39 10.17 0.54999 10.7 6.59 17.32 11.92l-0.01001-0.01001z"
      />
      <path
        className="MascotThumbsUpAndStar cls-2"
        d="m228.95 387.61 14.15 0.64001 5.03 0.23001 88.67 4.09-18.05-31.65-53.44 11.44 45.31-48.91c-6.11-3.97-12.26-7.92-18.37-11.9-0.09-0.04999-0.16-0.10999-0.25-0.16-7.56-4.89-37.32-24.82-44.37-29.39-10.17 0.54999-10.7 6.59-17.32 11.92 5.58 4.02 20.92 13.32 26.75 17.48 0.09 0.04999 0.16 0.10999 0.23001 0.16 7.3 5.24 14.58 10.52 21.89 15.75l-38.14 45.81-3.17 3.81-8.91 10.68-0.00999-6e-5z"
      />
      <path
        className="MascotThumbsUpAndStar cls-7"
        d="m230.3 293.92c5.58 4.02 20.92 13.32 26.75 17.48h34.93c-7.56-4.89-37.32-24.82-44.37-29.39-10.17 0.54999-10.7 6.59-17.32 11.92l0.01003-0.01001z"
      />
      <path
        className="MascotThumbsUpAndStar cls-4"
        d="m131.87 115.13h188.42v165.57c0 12.61-10.24 22.85-22.85 22.85h-142.72c-12.61 0-22.85-10.24-22.85-22.85v-165.57h3e-5z"
      />
      <rect
        className="MascotThumbsUpAndStar cls-4"
        x="98.214"
        y="94.787"
        width="255.73"
        height="49.3"
      />
      <path d="m189.23 152.66h73.69c2.0656 0 3.74 1.6744 3.74 3.74v-2e-5c0 2.0656-1.6744 3.74-3.74 3.74h-73.69c-2.0656 0-3.74-1.6744-3.74-3.74v2e-5c0-2.0656 1.6744-3.74 3.74-3.74z" />
      <path
        className="MascotThumbsUpAndStar cls-8"
        d="m207.85 232.84c4.56 1.6 15.11 4.5 26.03-0.38 8.81-3.93 13.8-11.12 16.17-15.24"
      />
      <path
        className="MascotThumbsUpAndStar cls-11"
        d="m268.81 187.2c0 3.75-1.06 7.26-2.89 10.23h-33.28c-1.83-2.98-2.89-6.48-2.89-10.23 0-10.79 8.74-19.53 19.53-19.53s19.53 8.74 19.53 19.53z"
      />
      <circle
        className="MascotThumbsUpAndStar cls-6"
        cx="248.19"
        cy="187.67"
        r="4.65"
      />
      <path
        className="MascotThumbsUpAndStar cls-3"
        d="m205.33 149.68 7.48 23.01h24.2c2.86 0 4.05 3.66 1.73 5.34l-19.58 14.22 7.48 23.01c0.88 2.72-2.23 4.98-4.54 3.3l-19.58-14.22-19.58 14.22c-2.31 1.68-5.42-0.58-4.54-3.3l7.48-23.01-19.58-14.22c-2.31-1.68-1.12-5.34 1.73-5.34h24.2l7.48-23.01c0.88-2.72 4.73-2.72 5.61 0h0.01003z"
      />
      <circle
        className="MascotThumbsUpAndStar cls-6"
        cx="201.78"
        cy="187.67"
        r="4.65"
      />
    </svg>
  );
};
