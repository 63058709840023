import React, { FC, memo, useEffect, useState } from "react";
import { BurnItem } from "./BurnItem";
import { Burn } from "@airmont/firefly/shared/ts/domain";
import { useHotkeys } from "react-hotkeys-hook";
import { Key } from "ts-key-enum";
import { ArrayUtils } from "@airmont/shared/ts/utils/core";
import { ScrollMenu, ScrollMenuItem } from "shared-ts-scroll-menu";
import { Box, Slide, SxProps } from "@mui/material";
import { delayDispatch } from "@airmont/shared/ts/ui/react";

export interface BurnListProps {
  burns: Array<Burn> | undefined;
  loading?: boolean | number;
  fetching?: boolean | number;
  selectedBurn?: Burn;
  onSelected?: (selected: Burn) => void;
  sx?: SxProps;
}

export const BurnList: FC<BurnListProps> = memo((props) => {
  const { burns, selectedBurn } = props;
  const loading = props.loading ?? false;
  const fetching = props.fetching ?? false;
  const [renderSkeletons, setRenderSkeletons] = useState<boolean>(
    burns === undefined
  );
  const [viewedBurns, setViewedBurns] = useState<Burn[] | undefined>(burns);
  useEffect(() => {
    const initialFetch =
      fetching && (burns === undefined || burns.length === 0);
    if (initialFetch) {
      console.log(`BurnList: eff: initialFetch: ${initialFetch}`);
    }

    const fromLoadingToSomething =
      burns !== undefined && viewedBurns === undefined;
    if (fromLoadingToSomething) {
      console.log(
        `BurnList: eff: fromLoadingToSomething: ${fromLoadingToSomething}`
      );
    }

    const fromSomeToNone =
      (viewedBurns?.length ?? 0) > 0 && (burns?.length ?? -1) === 0;
    if (fromSomeToNone) {
      console.log(`BurnList: eff: fromSomeToNone: ${fromSomeToNone}`);
    }

    if (fromLoadingToSomething) {
      console.log(
        `BurnList: eff: immediate setViewedBurns(${burns?.length ?? "undef"})`
      );
      setViewedBurns(burns);
    } else if (fromSomeToNone) {
      console.log(
        `BurnList: eff: delayed setViewedBurns(${burns?.length ?? "undef"})`
      );
      delayDispatch(() => setViewedBurns(burns), 1000);
    } else if (
      burns !== viewedBurns &&
      !(viewedBurns?.length === 0 && burns?.length === 0)
    ) {
      console.log(
        `BurnList: eff: immediate setViewedBurns(${burns?.length ?? "undef"})`
      );
      setViewedBurns(burns);
    }

    const fromZeroToSomeBurns =
      !fetching && (burns?.length ?? 0) > 0 && viewedBurns?.length === 0;
    if (fromZeroToSomeBurns) {
      console.log(`BurnList: eff: fromZeroToSomeBurns: ${fromZeroToSomeBurns}`);
    }

    if (initialFetch || fromZeroToSomeBurns) {
      console.log(`BurnList: eff: setRenderSkeletons(true)`);
      setRenderSkeletons(true);
    } else if (renderSkeletons) {
      console.log(`BurnList: eff: setRenderSkeletons(false)`);
      setRenderSkeletons(false);
    }
  }, [fetching, burns, viewedBurns, renderSkeletons]);

  console.log("BurnList: ************************");
  console.log(
    `BurnList: loading: ${loading}, fetching: ${fetching}, burns.length: ${
      burns?.length ?? "undef"
    }, viewedBurns.length: ${
      viewedBurns?.length ?? "undef"
    }, renderSkeletons: ${renderSkeletons}`
  );
  const handleSelectedBurn = (burn: Burn) => {
    props.onSelected?.(burn);
  };

  useHotkeys(Key.ArrowUp, () => {
    if (burns != null) {
      const selectedIndex = burns?.findIndex(
        (it) => it.id === selectedBurn?.id
      );
      if (selectedIndex > 0) {
        const nextBurn = burns[selectedIndex - 1];
        props.onSelected?.(nextBurn);
      }
    }
  });

  useHotkeys(Key.ArrowDown, () => {
    if (burns != null) {
      const selectedIndex = burns?.findIndex(
        (it) => it.id === selectedBurn?.id
      );
      if (selectedIndex < burns.length - 1) {
        const nextBurn = burns[selectedIndex + 1];
        props.onSelected?.(nextBurn);
      }
    }
  });
  if (renderSkeletons) {
    const numberOfSkeletons =
      typeof props.loading === "number" ? props.loading : 5;
    console.log(`BurnList: rendering ${numberOfSkeletons} skeletons`);
    return (
      <Slide
        in={true}
        direction={"left"}
        timeout={{
          enter: 500,
          exit: 500,
        }}
      >
        <Box>
          <ScrollMenu gap={1} scrollToItem={selectedBurn?.id} sx={props.sx}>
            {ArrayUtils.createAndFill(numberOfSkeletons, (index) => {
              return (
                <ScrollMenuItem itemKey={index.toString()} key={index}>
                  <BurnItem key={index} />
                </ScrollMenuItem>
              );
            })}
          </ScrollMenu>
        </Box>
      </Slide>
    );
  } else if (viewedBurns !== undefined) {
    console.log("BurnList: rendering viewedBurns");
    return (
      <Slide
        in={burns?.length === viewedBurns.length}
        direction={"left"}
        timeout={{
          enter: 500,
          exit: 500,
        }}
      >
        <Box>
          <ScrollMenu gap={1} scrollToItem={selectedBurn?.id} sx={props.sx}>
            {viewedBurns.map((burn) => {
              const selected = burn.id === selectedBurn?.id;
              return (
                <ScrollMenuItem itemKey={burn.id} key={burn.id}>
                  <BurnItem
                    burn={burn}
                    selected={selected}
                    onSelect={handleSelectedBurn}
                  />
                </ScrollMenuItem>
              );
            })}
          </ScrollMenu>
        </Box>
      </Slide>
    );
  } else if (burns !== undefined) {
    console.log("BurnList: rendering burns");
    return (
      <Slide in={false} exit={false} direction={"left"}>
        <Box>
          <ScrollMenu gap={1} scrollToItem={selectedBurn?.id} sx={props.sx}>
            {burns.map((burn) => {
              const selected = burn.id === selectedBurn?.id;
              return (
                <ScrollMenuItem itemKey={burn.id} key={burn.id}>
                  <BurnItem
                    burn={burn}
                    selected={selected}
                    onSelect={handleSelectedBurn}
                  />
                </ScrollMenuItem>
              );
            })}
          </ScrollMenu>
        </Box>
      </Slide>
    );
  } else {
    console.log("BurnList: rendering what?");
    return <Box>Uncovered state...</Box>;
  }
});
