import { FC, ReactNode } from "react";

export interface IfProps {
  expression: boolean;
  then: ReactNode | ((children: ReactNode | undefined) => ReactNode);
  else: ReactNode | ((children: ReactNode | undefined) => ReactNode);
  children?: ReactNode;
}

export const If: FC<IfProps> = (props) => {
  if (props.expression) {
    return typeof props.then === "function"
      ? props.then(props.children)
      : props.then;
  } else {
    return typeof props.else === "function"
      ? props.else(props.children)
      : props.else;
  }
};
