import React, { FC } from "react";
import { Stack, SxProps, Tooltip, Typography } from "@mui/material";
import { Burn } from "@airmont/firefly/shared/ts/domain";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { DurationDisplay } from "@airmont/shared/ts/ui/time";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import { InfoPill } from "@airmont/shared/ts/ui/info-pill";
import { Button_color } from "shared-ts-mui";

export interface BurnInfoPillProps {
  burn: Burn;
  hideDate?: boolean;
  hideEndDate?: boolean;
  color?: Button_color;
  variant?: "normal" | "outlined" | "filled";
  layout: SizeClass;
  sx?: SxProps;
}

export const BurnInfoPill: FC<BurnInfoPillProps> = (props) => {
  const { burn, layout } = props;
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );

  const formatDate = (dateTime?: DateTime) => {
    if (props.hideDate ?? true) {
      return dateTime?.toLocaleString(DateTime.TIME_SIMPLE);
    }
    return dateTime?.toLocaleString(DateTime.DATETIME_MED);
  };

  return (
    <InfoPill
      color={props.color}
      variant={props.variant}
      label={`${tFireflySharedDomain("Burn")} #${burn.startTime.day}${
        (burn.countOfDay ?? 0) > 1 ? ` (${burn.countOfDay})` : ""
      } `}
      layout={layout}
      info={
        <Stack
          direction={"row"}
          useFlexGap
          gap={1}
          sx={{ alignItems: "center" }}
        >
          <Tooltip title={tFireflySharedDomain("Burn Duration")}>
            <TimelapseRoundedIcon
              fontSize={"small"}
              sx={{ color: "text.secondary" }}
            />
          </Tooltip>
          {!burn.anonymized && (
            <>
              <Typography noWrap>{formatDate(burn.startTime)}</Typography>
              {!(props.hideEndDate ?? true) && (
                <>
                  <Typography>➡</Typography>
                  <Typography noWrap>{formatDate(burn.endTime)}</Typography>
                </>
              )}
            </>
          )}
          <Typography>≈</Typography>
          <DurationDisplay
            duration={burn.duration}
            maxUnits={1}
            layout={SizeClass.Compact}
          />
        </Stack>
      }
    />
  );
};
