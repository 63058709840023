import { CSSProperties, FC } from "react";

export interface MascotHotProps {
  mode: "light" | "dark";
  style?: CSSProperties;
}

export const MascotHot: FC<MascotHotProps> = (props) => {
  const hairColor = props.mode === "dark" ? "#fff" : "#fff";
  return (
    <svg
      version="1.1"
      viewBox={"0 0 275.5 493.22"}
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
      className={"MascotHot"}
    >
      <defs>
        <style>{`
          .MascotHot.st0, .MascotHot.st1, .MascotHot.st2 {
            fill: none;
          }

          .MascotHot.st3 {
            fill: #9cc;
          }

          .MascotHot.st4 {
            mix - blend - mode: multiply;
            opacity: .57;
          }

          .MascotHot.st4, .MascotHot.st5 {
            isolation: isolate;
          }

          .MascotHot.st4, .MascotHot.st6 {
            fill: #e87643;
          }

          .MascotHot.st1 {
            stroke: #d84816;
          }

          .MascotHot.st1, .MascotHot.st2 {
            stroke - linecap: round;
            stroke-linejoin: round;
            stroke-width: 2.45px;
          }

          .MascotHot.st7 {
            fill: #98cbcb;
          }

          .MascotHot.st8 {
            fill: #e1f1ef;
          }

          .MascotHot.st9 {
            stroke: #000;
            stroke-miterlimit: 10;
            stroke-width: .03px;
          }

          .MascotHot.st10 {
            fill: #fff;
          }

          .MascotHot.st2 {
            stroke: #ededed;
          }

          .MascotHot.st11 {
            clip - path: url(#clippath);
          }`}</style>
        <clipPath id="clippath">
          <path
            className="MascotHot st0"
            d="m174 113.51v-23.84h-77.46v23.87l-32.42 13.62c21.08 10.08 32.21 23.95 32.42 41.98v45h77.46v-45h0.06c0.2-18.04 11.34-31.9 32.42-41.98l-32.48-13.65z"
          />
        </clipPath>
      </defs>
      <g className="MascotHot st5">
        <g data-name="Layer_1">
          <path
            className="MascotHot st3"
            d="M135.28,488.26l-14.15.64-5.03.23-88.67,4.09,18.05-31.65,53.44,11.44-45.31-48.91c6.11-3.97,12.26-7.92,18.37-11.9.09-.05.16-.11.25-.16,7.56-4.89,37.32-24.82,44.37-29.39,10.17.55,10.7,6.59,17.32,11.92-5.58,4.02-20.92,13.32-26.75,17.48-.09.05-.16.11-.23.16-7.3,5.24-14.58,10.52-21.89,15.75l38.14,45.81,3.17,3.81,8.91,10.68h0Z"
          />
          <path
            className="MascotHot st9"
            d="m133.92 394.56c-5.58 4.02-20.92 13.32-26.75 17.48h-34.93c7.56-4.89 37.32-24.82 44.37-29.39 10.17 0.55 10.7 6.59 17.32 11.92z"
          />
          <path
            className="MascotHot st3"
            d="M140.22,488.26l14.15.64,5.03.23,88.67,4.09-18.05-31.65-53.44,11.44,45.31-48.91c-6.11-3.97-12.26-7.92-18.37-11.9-.09-.05-.16-.11-.25-.16-7.56-4.89-37.32-24.82-44.37-29.39-10.17.55-10.7,6.59-17.32,11.92,5.58,4.02,20.92,13.32,26.75,17.48.09.05.16.11.23.16,7.3,5.24,14.58,10.52,21.89,15.75l-38.14,45.81-3.17,3.81-8.91,10.68h0Z"
          />
          <path
            className="MascotHot st9"
            d="m141.58 394.56c5.58 4.02 20.92 13.32 26.75 17.48h34.93c-7.56-4.89-37.32-24.82-44.37-29.39-10.17 0.55-10.7 6.59-17.32 11.92z"
          />
          <path
            className="MascotHot st6"
            d="m174 113.51v-23.84h-77.46v23.87l-32.42 13.62c21.08 10.08 32.21 23.95 32.42 41.98v45h77.46v-45h0.06c0.2-18.04 11.34-31.9 32.42-41.98l-32.48-13.65z"
          />
          <g className="MascotHot st11" clip-path="url(#clippath)">
            <path
              className="MascotHot st4"
              d="M174,113.51v-23.84h-77.46v23.87l-32.42,13.62c11.64,5.57,20.25,12.3,25.66,20.24,2-1.38,4.72-1.73,7.08-1.65,2.35.09,4.85.53,7.03,1.43,3.22,1.32,5.54,3.81,7.41,6.64-.08-.98-.22-1.95-.36-2.92-.29-2-.6-4-.81-6.01-.34-3.27-.55-6.97.97-10,1.44-2.86,5.05-4.64,8.19-3.72,3.28.96,4.58,4.68,5.24,7.68.35,1.59.55,3.21.75,4.83.2,1.62.4,3.23.54,4.84.34-.9.78-1.75,1.38-2.52,2.15-2.76,6.56-3.76,9.7-2.15,1.59.82,2.43,2.37,3.01,3.99.19.54.36,1.1.51,1.67.49-1.35,1.26-2.6,2.31-3.65,1.78-1.78,4.19-2.88,6.66-3.27,1.66-.26,3.24.03,4.84.53,1.67.53,3.57,1.28,4.74,2.64.12.14.22.29.31.43.87-1.21,1.96-2.27,3.25-3.09,2.25-1.44,4.95-2.07,7.6-2.12,2.9-.05,5.5.84,7.88,2.46,1.3.89,2.43,1.98,3.32,3.25,5.43-7.63,13.87-14.12,25.16-19.52l-32.48-13.65h0Z"
            />
          </g>
          <path
            className="MascotHot st1"
            d="m105.14 137.64c9.78 17.69 10.92 41.84 8.26 68.85"
          />
          <path
            className="MascotHot st1"
            d="m163.85 135.89c-9.78 17.69-10.92 41.84-8.26 68.85"
          />
          <path
            className="MascotHot st1"
            d="m121.02 119.69c5.3 25.84 6.81 51.21 4.02 76.05"
          />
          <line
            className="MascotHot st1"
            x1="140.12"
            x2="140.12"
            y1="141.01"
            y2="211.69"
          />
          <path
            className="MascotHot st8"
            d="M264.37,89.51c-4.79-3.53-10.44-5.19-16.22-5.16-.57-19.84-14.85-35.72-32.39-35.72-2.86,0-5.63.43-8.27,1.22.11-.54.21-1.09.29-1.64,3.2-22.31-16.54-43.61-44.09-47.56-23.22-3.33-44.57,6.71-52.64,23.3-4.22-2.3-8.95-3.59-13.94-3.59-16.02,0-29.32,13.25-31.94,30.65-5.35-6.08-12.74-9.84-20.89-9.84-16.35,0-29.6,15.1-29.6,33.73,0,4.06.63,7.96,1.79,11.56C6.71,91.97,0,103.43,0,116.68c0,18.63,13.25,33.73,29.6,33.73,9.65,0,18.21-5.26,23.62-13.4,14.66,6.79,32.99,3.84,45.5-8.67,3.22-3.22,5.8-6.84,7.76-10.68,2.29.93,4.74,1.46,7.31,1.46,4.48,0,8.64-1.54,12.1-4.18,6.45,12.5,18.37,20.9,32.01,20.9,5.79,0,11.26-1.51,16.14-4.2,1.99,2.44,4.39,4.55,7.23,6.19,10.86,6.27,24.57,3.93,34.58-4.78,1.86,4.22,4.71,7.96,8.56,10.8,13.17,9.69,32.79,5.37,43.83-9.63s9.31-35.02-3.85-44.71Z"
          />
          <path className="MascotHot st2" d="m35 117.58s4.32 13 18.21 19.43" />
          <path
            className="MascotHot st2"
            d="M106.46,117.66c.4-1.6.76-3.53.92-5.73.13-1.82.1-3.48,0-4.91"
          />
          <path
            className="MascotHot st2"
            d="M111.05,23.93s-9.95,16.48.43,36.02"
          />
          <path
            className="MascotHot st2"
            d="m207.49 49.84s-1.24 15.73-14.62 21.88"
          />
          <path
            className="MascotHot st2"
            d="M174.03,131.64s-6.65-9.88.04-24.6"
          />
          <path
            className="MascotHot st2"
            d="m248.15 84.34s0 17.79-13.37 27.78"
          />
          <path
            className="MascotHot st7"
            d="m43.54 214.77h188.42v165.57c0 12.61-10.24 22.85-22.85 22.85h-142.72c-12.61 0-22.85-10.24-22.85-22.85v-165.57z"
          />
          <rect
            className="MascotHot st7"
            x="9.89"
            y="194.43"
            width="255.73"
            height="49.3"
          />
          <circle
            className="MascotHot st10"
            cx="159.44"
            cy="287.76"
            r="19.97"
          />
          <circle
            className="MascotHot st10"
            cx="115.88"
            cy="287.76"
            r="19.97"
          />
          <path
            className="MascotHot st10"
            d="M178.89,288.92c-.76,10.78-10.09,18.9-20.87,18.15-9.6-.68-17.09-8.16-18.08-17.4l12.43-5.79,10.18-4.74,10.95-5.1c3.68,3.85,5.78,9.16,5.38,14.89Z"
          />
          <path
            className="MascotHot st10"
            d="M96.43,288.92c.76,10.78,10.09,18.9,20.87,18.15,9.6-.68,17.09-8.16,18.08-17.4l-12.43-5.79-10.18-4.74-10.95-5.1c-3.68,3.85-5.78,9.16-5.38,14.89Z"
          />
          <circle cx="115.88" cy="278.24" r="4.59" />
          <circle cx="159.44" cy="278.24" r="4.59" />
          <rect
            x="96.57"
            y="252.31"
            width="81.17"
            height="7.48"
            rx="3.74"
            ry="3.74"
          />
          <ellipse cx="137.75" cy="321.54" rx="7.12" ry="9.8" />
        </g>
      </g>
    </svg>
  );
};
