import { CSSProperties, useMemo } from "react";
import {
  MascotButterflies,
  MascotHappy,
  MascotHot,
  MascotSleeping,
  MascotThumbsDown,
  MascotThumbsUpAndStar,
} from "@airmont/firefly/my-chimney/ts/shared";
import { Theme } from "@mui/material";
import { BurnQualityCountDataPoint } from "@airmont/firefly/shared/ts/domain";

export const useMascotFeedback = (
  dataPoint: BurnQualityCountDataPoint,
  theme: Theme
) => {
  return useMemo(() => {
    const style: CSSProperties = {};

    if (dataPoint.goodInPercent == null) {
      return <MascotSleeping mode={theme.palette.mode} style={style} />;
    } else if (dataPoint.goodInPercent >= 83) {
      return <MascotThumbsUpAndStar mode={theme.palette.mode} style={style} />;
    } else if (dataPoint.goodInPercent >= 67) {
      return <MascotButterflies mode={theme.palette.mode} style={style} />;
    } else if (dataPoint.goodInPercent >= 34) {
      return <MascotHappy mode={theme.palette.mode} style={style} />;
    } else if (dataPoint.goodInPercent >= 17) {
      return <MascotThumbsDown mode={theme.palette.mode} style={style} />;
    } else {
      return <MascotHot mode={theme.palette.mode} style={style} />;
    }
  }, [dataPoint.goodInPercent, theme.palette.mode]);
};
