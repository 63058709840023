import { useMemo } from "react";
import { DateTime, Interval } from "luxon";
import { TimeframeUnit } from "@airmont/shared/ts/ui/timeframe";
import { FlueMetricNaturalKey } from "@airmont/firefly/shared/ts/domain";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";

export const useConvertFlueMetricNaturalKeyToTimeframeProps = (args: {
  flueMetricTimeItem: FlueMetricNaturalKey;
  flue: Flue;
  now: DateTime<true>;
}): { timeframe: Interval<true>; timeframeUnit: TimeframeUnit } | undefined => {
  const { flueMetricTimeItem, flue, now } = args;
  return useMemo(() => {
    if (flueMetricTimeItem.unit === "Ever") {
      return {
        timeframe: Interval.fromDateTimes(
          flue.audit.created.startOf("month"),
          now
        ) as Interval<true>,
        timeframeUnit: "year",
      };
    } else if (flueMetricTimeItem.unit === "SinceSweep") {
      const timeframeUnit = "month";
      const start =
        flue.fields.lastSweepDate != null
          ? (DateTime.fromISO(flue.fields.lastSweepDate) as DateTime<true>)
          : flue.audit.created;

      return {
        timeframe: Interval.fromDateTimes(
          start.startOf(timeframeUnit),
          now
        ) as Interval<true>,
        timeframeUnit: timeframeUnit,
      };
    }

    if (
      flueMetricTimeItem.time == null ||
      flueMetricTimeItem.unit === "AtSweep"
    ) {
      return undefined;
    }
    const timeframeUnit =
      flueMetricTimeItem.unit.toLowerCase() as unknown as TimeframeUnit;
    const timeframeStart = flueMetricTimeItem.time;
    const timeframeEnd = timeframeStart.plus({ [timeframeUnit]: 1 });
    const resolvedTimeframeUnit =
      flueMetricTimeItem.unit === "Year"
        ? "year"
        : flueMetricTimeItem.unit === "Month"
        ? "month"
        : "month";
    return {
      timeframe: Interval.fromDateTimes(
        timeframeStart.startOf(resolvedTimeframeUnit),
        timeframeEnd
      ) as Interval<true>,
      timeframeUnit: resolvedTimeframeUnit,
    };
  }, [
    flueMetricTimeItem.time,
    flueMetricTimeItem.unit,
    flue.fields.lastSweepDate,
    flue.audit.created,
    now,
  ]);
};
