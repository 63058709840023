import {
  BurnAssessment,
  BurnAssessmentColor,
  resolveBurnAssessmentIcon,
} from "@airmont/firefly/shared/ts/domain";
import { Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { darkModeTextShadow } from "../../shared/darkMode_textShadow";

export interface BurnStartAssessmentInfoProps {
  assessment: BurnAssessment;
  value: number | undefined;
}

export const BurnStartAssessmentInfo: FC<BurnStartAssessmentInfoProps> = (
  props
) => {
  const { assessment, value } = props;
  const theme = useTheme();
  const AssessmentIcon = resolveBurnAssessmentIcon(assessment);
  return (
    <Stack
      sx={{
        height: "73px",
        width: "64px",
        border: "1px solid " + theme.palette.divider,
        borderRadius: 1,
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <AssessmentIcon
        fontSize={"small"}
        sx={{
          color: BurnAssessmentColor[assessment],
        }}
      />
      <Typography
        sx={{
          textShadow:
            theme.palette.mode === "dark" ? darkModeTextShadow : undefined,
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};
