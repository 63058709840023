import React, { FC, useMemo } from "react";
import { Form, Formik } from "formik";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import { Card, CardContent, CardHeader, Toolbar } from "@mui/material";
import { Button } from "shared-ts-mui";
import {
  BooleanSetting,
  useUserSetting,
} from "@airmont/shared/ts/utils/user-settings";
import { useI18Next } from "@airmont/shared/ts/utils/i18n";
import { useTranslation } from "react-i18next";
import { McUser } from "firefly/my-chimney/ts/user";
import { ConsentBurnTimeIsSavedProperty } from "./ConsentBurnTimeIsSavedProperty";
import { Property, ValueOption } from "shared-ts-property";
import {
  AnonymizeUsageDataProperty,
  ConsentToCollectUsageDataProperty,
  useAnonymizeUsageData,
  useConsentToCollectUsageData,
} from "shared-ts-posthog";
import {
  AnonymizeIssueMonitoringDataProperty,
  ConsentToMonitorIssuesProperty,
  useAnonymizeIssueMonitoringData,
  useConsentToMonitorIssues,
} from "shared-ts-sentry";
import { useNavigate } from "react-router";
import { useLanguage } from "shared-ts-language";

type UserSettingsFormValues = {
  language: string;
  consentToMonitorIssues: boolean;
  anonymizeIssueMonitoringData: boolean;
  consentBurnTimeIsSaved: boolean;
  consentToCollectUsageData: boolean;
  anonymizeUsageData: boolean;
};

export interface UserSettingsProps {
  user: McUser;
}

export const UserSettings: FC<UserSettingsProps> = (props) => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const navigate = useNavigate();
  const { language, setLanguage } = useLanguage();
  const [consentBurnTimeIsSaved, setConsentBurnTimeIsSaved] = useUserSetting(
    "consentBurnTimeIsSaved",
    BooleanSetting
  );
  const [consentToMonitorIssues, setConsentToMonitorIssues] =
    useConsentToMonitorIssues();
  const [anonymizeIssueMonitoringData, setAnonymizeIssueMonitoringData] =
    useAnonymizeIssueMonitoringData();
  const [consentToCollectUsageData, setConsentToCollectUsageData] =
    useConsentToCollectUsageData();
  const [anonymizeUsageData, setAnonymizeUsageData] = useAnonymizeUsageData();
  const { languages } = useI18Next();
  const languagesValueOptions: Array<ValueOption> = useMemo(
    () =>
      languages.map((it) => {
        /* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF
        return {
          id: it,
          label: t(it as any),
        };
      }),
    [languages, t]
  );

  const initialFormValues: UserSettingsFormValues = {
    language: language,
    consentToMonitorIssues: consentToMonitorIssues,
    anonymizeIssueMonitoringData: anonymizeIssueMonitoringData,
    consentBurnTimeIsSaved: consentBurnTimeIsSaved,
    consentToCollectUsageData: consentToCollectUsageData,
    anonymizeUsageData: anonymizeUsageData,
  };

  const handleSubmit = async (values: UserSettingsFormValues) => {
    setLanguage(values.language);
    setConsentBurnTimeIsSaved(values.consentBurnTimeIsSaved);
    setConsentToMonitorIssues(values.consentToMonitorIssues);
    setAnonymizeIssueMonitoringData(values.anonymizeIssueMonitoringData);
    setConsentToCollectUsageData(values.consentToCollectUsageData);
    setAnonymizeUsageData(values.anonymizeUsageData);
    /* PostHog and Sentry needs when their settings are changed
     */
    if (
      values.consentToMonitorIssues !== consentToMonitorIssues ||
      values.anonymizeIssueMonitoringData !== anonymizeIssueMonitoringData ||
      values.consentToCollectUsageData !== consentToCollectUsageData ||
      values.anonymizeUsageData !== anonymizeUsageData
    ) {
      navigate(0);
    }
    return Promise.resolve();
  };

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, dirty, setFieldValue, resetForm }) => {
        const handleReset = () => {
          resetForm();
        };
        return (
          <Form>
            <Card>
              <CardHeader subheader={t("User Settings")} />
              <CardContent>
                <PropertiesCard
                  elevation={0}
                  childPropertyProps={{
                    fullWidth: true,
                  }}
                >
                  <Property
                    name={"language"}
                    label={t("Language")}
                    value={values.language}
                    type={"string"}
                    valueOptions={{
                      multiple: false,
                      restrictToOptions: true,
                      options: languagesValueOptions,
                    }}
                    onChange={(value, name) => setFieldValue(name, value)}
                    mode={"edit"}
                  />
                  <ConsentBurnTimeIsSavedProperty
                    value={values.consentBurnTimeIsSaved}
                    onChange={setFieldValue}
                    sx={{ mb: 1 }}
                  />
                  <ConsentToMonitorIssuesProperty
                    name={"consentToMonitorIssues"}
                    value={values.consentToMonitorIssues}
                    onFormikFieldValueChange={setFieldValue}
                  />
                  {values.consentToMonitorIssues && (
                    <AnonymizeIssueMonitoringDataProperty
                      name={"anonymizeIssueMonitoringData"}
                      value={values.anonymizeIssueMonitoringData}
                      onFormikFieldValueChange={setFieldValue}
                    />
                  )}
                  <ConsentToCollectUsageDataProperty
                    name={"consentToCollectUsageData"}
                    value={values.consentToCollectUsageData}
                    onFormikFieldValueChange={setFieldValue}
                  />
                  {values.consentToCollectUsageData && (
                    <AnonymizeUsageDataProperty
                      name={"anonymizeUsageData"}
                      value={values.anonymizeUsageData}
                      onFormikFieldValueChange={setFieldValue}
                    />
                  )}
                </PropertiesCard>
              </CardContent>
              <Toolbar sx={{ gap: 1 }}>
                <Button type={"submit"} variant={"outlined"} disabled={!dirty}>
                  {t("Save")}
                </Button>
                <Button
                  variant={"text"}
                  disabled={!dirty}
                  onClick={handleReset}
                >
                  {t("Reset")}
                </Button>
              </Toolbar>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};
