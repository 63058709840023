import { Attributes, FC, useMemo } from "react";
import {
  Box,
  Button,
  Skeleton,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Burn,
  BurnAssessment,
  BurnAssessmentColor,
  resolveBurnAssessmentIcon,
} from "@airmont/firefly/shared/ts/domain";
import { Button_color, useSxMerge } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { TooltipOrNot } from "@airmont/shared/ts/ui/tooltip";
import WhatshotRoundedIcon from "@mui/icons-material/WhatshotRounded";
import { red } from "@mui/material/colors";

export interface BurnItemProps extends Attributes {
  burn?: Burn;
  loading?: boolean;
  anonymizedTime?: boolean;
  selected?: boolean;
  color?: Button_color;
  sx?: SxProps;
  onSelect?: (burn: Burn) => void;
}

export const BurnItem: FC<BurnItemProps> = (props) => {
  const { burn } = props;
  const anonymizedTime = burn?.anonymized ?? props.anonymizedTime;
  const loading = props.loading ?? burn === undefined;
  const { t: t_firefly_shared_domain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const selected = props.selected ?? false;
  const theme = useTheme();
  const BurnAssessmentIcon = useMemo(
    () =>
      notUndef(burn, (burn) =>
        resolveBurnAssessmentIcon(burn.analytics.startQuality)
      ),
    [burn]
  );

  const burnAssessmentColor = useMemo(() => {
    return notUndef(
      burn,
      (burn) => BurnAssessmentColor[burn.analytics.startQuality]
    );
  }, [burn]);
  const burnAssessmentScale = useMemo(
    () =>
      notUndef(burn, (burn) =>
        burn.analytics.startQuality === BurnAssessment.Good ? "1.45" : undefined
      ),
    [burn]
  );

  const sx: SxProps = useSxMerge(props.sx, {
    minWidth: "fit-content",
    pl: 0,
    pr: 0,
    pt: 0,
    pb: 0,
  });

  const handleClick = () => {
    if (props.burn != null && props.onSelect != null) {
      props.onSelect?.(props.burn);
    }
  };

  return (
    <TooltipOrNot
      title={notUndef(burn, (burn) =>
        t_firefly_shared_domain("{{BurnAssessment}} ignition", {
          BurnAssessment: t_firefly_shared_domain(
            `BurnAssessment.${burn?.analytics.startQuality}`
          ),
        })
      )}
    >
      <Button
        color={selected ? "primary" : "inherit"}
        variant={selected ? "contained" : "text"}
        sx={sx}
        onClick={handleClick}
      >
        <Stack
          direction={"column"}
          useFlexGap
          gap={0.5}
          sx={{
            alignItems: "center",
            pt: 0.5,
            pl: 2,
            pr: 2,
            pb: 1,
            position: "relative",
          }}
        >
          {loading && <Skeleton variant="text" width={20} height={25} />}
          {!loading && (
            <Typography variant={"button"} sx={{ minHeight: "25px" }}>
              {anonymizedTime && `#${burn?.count}`}
              {!anonymizedTime && (
                <>
                  {burn?.startTime.day}.
                  {!anonymizedTime && burn?.countOfDay != null && (
                    <span
                      style={{ fontSize: "smaller", verticalAlign: "sub" }}
                    >{`${burn.countOfDay}`}</span>
                  )}
                </>
              )}
            </Typography>
          )}
          {!loading && (
            <Box
              sx={{
                borderRadius: "50%",
                padding: 0.75,
                backgroundColor: selected
                  ? theme.palette.background.paper
                  : undefined,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                filter: "drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.9))",
              }}
            >
              {BurnAssessmentIcon && (
                <BurnAssessmentIcon
                  fontSize={"small"}
                  sx={{
                    color: burnAssessmentColor,
                    scale: burnAssessmentScale,
                  }}
                />
              )}
            </Box>
          )}
          {loading && (
            <>
              <Skeleton
                animation="wave"
                variant="circular"
                width={32}
                height={32}
              />
              <WhatshotRoundedIcon
                fontSize={"small"}
                sx={{
                  position: "absolute",
                  bottom: 10,
                  zIndex: 1,
                  opacity: 0.1,
                  color: red["300"],
                }}
              />
            </>
          )}
        </Stack>
      </Button>
    </TooltipOrNot>
  );
};
